import { Typography } from '@mui/joy';
import FormCheckbox from '../../../technical/form/FormCheckbox.tsx';
import { FormInput } from '../../../technical/inputs';
import { useFormContext } from 'react-hook-form';

const OkxV2MasterCredentials = () => {
  const { watch, clearErrors } = useFormContext();
  const useMasterCredentials = watch('secrets.use_master_credentials');
  const useMasterCredentialsElement = (
    <FormCheckbox
      name={'secrets.use_master_credentials'}
      label={'Provide master credentials'}
      onChange={(val) => {
        if (!val) {
          clearErrors('secrets.master_key');
        }
      }}
    />
  );

  if (!useMasterCredentials) {
    return useMasterCredentialsElement;
  }

  return (
    <>
      {useMasterCredentialsElement}
      <Typography level="title-lg">Master account</Typography>
      <FormInput
        label={'Password'}
        name={'secrets.master_key.password'}
        autoComplete="off"
        width="fullWidth"
        type={'password'}
        showLabelAboveInput
      />
      <FormInput
        label={'Api key'}
        name={'secrets.master_key.api_key'}
        autoComplete="off"
        width="fullWidth"
        showLabelAboveInput
      />
      <FormInput
        label={'Secret'}
        name={'secrets.master_key.secret'}
        autoComplete="off"
        width="fullWidth"
        type={'password'}
        showLabelAboveInput
      />
    </>
  );
};

export default OkxV2MasterCredentials;
