import { Box, Stack } from '@mui/joy';
import type { ReactElement } from 'react';
import React, { useEffect, useRef, useState } from 'react';
import Loader from '../../../technical/Loader/Loader.tsx';
import { useIntersection } from 'react-use';
import isNil from 'lodash/fp/isNil';
import type { Message } from './Message.ts';
import { MessageView } from './MessageView.tsx';
import { GraphQLErrorMessage } from '../../../technical/form/GraphQLApiErrorMessage.tsx';
import type { IReactionType } from '../../../../generated/graphql.tsx';

const MessageList = ({
  messageItems,
  fetchMore,
  hasMoreItems,
  loadingData,
  loadedInitialHistory,
  lastFetchPerfTime,
  loadingError,
  updateReaction,
}: {
  messageItems: Message[];
  fetchMore: () => Promise<void>;
  hasMoreItems: boolean;
  loadingData: boolean;
  loadedInitialHistory: boolean;
  lastFetchPerfTime: DOMHighResTimeStamp;
  loadingError?: Error | null;
  updateReaction: (id: string, newReaction: IReactionType | null) => void;
}): ReactElement => {
  const wrapperRef = useRef<HTMLElement | null>(null);
  const topAnchorRef = useRef<HTMLDivElement | null>(null);
  const bottomAnchorRef = useRef<HTMLDivElement | null>(null);
  const [scrolledToTheEnd, setScrolledToTheEnd] = useState(false);
  const [shouldAnchorLastMessage, setShouldAnchorLastMessage] = useState(true);
  const bottomAnchorIntersection = useIntersection(bottomAnchorRef, {
    root: wrapperRef.current,
    rootMargin: '0px 0px 50px 0px',
  });

  useEffect(() => {
    if (isNil(bottomAnchorIntersection)) {
      return;
    }

    if (bottomAnchorIntersection.isIntersecting) {
      setShouldAnchorLastMessage(true);
      return;
    }

    if (shouldAnchorLastMessage) {
      setShouldAnchorLastMessage(false);
    }
  }, [bottomAnchorIntersection, shouldAnchorLastMessage]);

  useEffect(() => {
    if (loadedInitialHistory && !scrolledToTheEnd) {
      bottomAnchorRef.current?.scrollIntoView();
      setTimeout(() => {
        // give browser time to scroll before we can trigger a request
        setScrolledToTheEnd(true);
      }, 50);
    }
  }, [loadedInitialHistory, scrolledToTheEnd]);

  const topAnchorIntersection = useIntersection(topAnchorRef, {
    root: wrapperRef.current,
    rootMargin: '200px 0px 0px 0px',
  });

  const roundedLastFetchPerfTime = Math.round(lastFetchPerfTime);
  useEffect(() => {
    if (!hasMoreItems || loadingData || !topAnchorIntersection?.isIntersecting || !scrolledToTheEnd) {
      return;
    }

    if (topAnchorIntersection.time < roundedLastFetchPerfTime) {
      return;
    }

    fetchMore();
  }, [topAnchorIntersection, loadingData, fetchMore, hasMoreItems, scrolledToTheEnd, roundedLastFetchPerfTime]);

  return (
    <Box
      ref={wrapperRef}
      m={-2}
      p={2}
      sx={{
        minHeight: 0,
        overflow: 'auto',
        overscrollBehavior: 'contain',
      }}
    >
      <Stack
        rowGap={3}
        sx={{
          minHeight: 0,
          '& *': {
            overflowAnchor: 'none',
          },
        }}
      >
        {loadingData && <Loader variant={'small'} />}
        <GraphQLErrorMessage error={loadingError} />
        <Box height={'1px'} width={'100%'} ref={topAnchorRef} />
        {messageItems.map((msg) => {
          return (
            <MessageView
              key={msg.id}
              msg={msg}
              anchorLastMessage={shouldAnchorLastMessage}
              updateReaction={(reaction) => {
                updateReaction(msg.id, reaction);
              }}
            />
          );
        })}
        <Box
          /* scroll pinning https://css-tricks.com/books/greatest-css-tricks/pin-scrolling-to-bottom/ */
          height={'1px'}
          width={'100%'}
          ref={bottomAnchorRef}
          sx={{
            overflowAnchor: shouldAnchorLastMessage ? 'auto' : 'none',
          }}
        />
      </Stack>
    </Box>
  );
};

export default React.memo(MessageList) as typeof MessageList;
