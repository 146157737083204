import TableChartOutlinedIcon from '@mui/icons-material/TableChartOutlined';
import { Stack } from '@mui/joy';
import Avatar from '@mui/joy/Avatar';
import { type ColumnDef, createColumnHelper } from '@tanstack/react-table';
import type { CellContext, SortingState } from '@tanstack/table-core';
import type { Dayjs } from 'dayjs';
import capitalize from 'lodash/fp/capitalize';
import isNil from 'lodash/fp/isNil';
import { bignumber } from 'mathjs';
import { type ReactElement, type ReactNode, useEffect, useMemo, useState } from 'react';
import { useDebounce } from 'react-use';
import { downloadTransactionOrderCsv } from 'components/file.utils.ts';
import {
  createAccountIdAutocompleteOptions,
  createSubAccountIdAutocompleteOptions,
  type CreateSubAccountIdAutocompleteOptionsInputAccount,
} from 'components/portfolio/account/AccountService.tsx';
import { useRegisterActions } from 'components/technical/actions/UseRegisterActions';
import { useSearchableAssetsIds } from 'components/technical/Appbar/AppbarSearch/useSearchableAssets';
import SeeMoreDropdown from 'components/technical/SeeMoreDropDown/SeeMoreDropdown.tsx';
import { useFeedback } from 'components/technical/Feedback/UseFeedback.tsx';
import ConfirmationDialog from 'components/technical/form/dialog/ConfirmationDialog';
import GTable from 'components/technical/GTable/GTable';
import type { Filter } from 'components/technical/GTable/GTable.props';
import { useTablePaginator } from 'components/technical/GTable/UseTablePaginator.tsx';
import { defaultHeaderActionProps } from 'components/technical/HeaderBar/DefaultHeaderActionProps.ts';
import StaticMultiAutocomplete from 'components/technical/inputs/Autocomplete/StaticMultiAutocomplete';
import type { StaticAutocompleteOption } from 'components/technical/inputs/Autocomplete/StaticSingleAutocomplete.props';
import DateRangeInput, { type DateRangeInputProps } from 'components/technical/inputs/date/DateRangeInput';
import AsyncActionButton from 'components/technical/inputs/GButton/AsyncActionButton';
import GInput from 'components/technical/inputs/GInput/GInput.tsx';
import Loader from 'components/technical/Loader/Loader.tsx';
import { useUserTimezone } from 'components/technical/UseUserTimezone.tsx';
import { GValueWithChangeCell } from 'components/technical/ValueChange/GValueChange';
import WarningTooltip from 'components/technical/WarningTooltip/WarningTooltip.tsx';
import { emptyToUndefined } from './TransactionService.ts';
import TransactionTagsView from './TransactionTagsView.tsx';
import UpdateTransactionDialogContainer from './UpdateTransactionDialog';
import {
  IAction,
  IAssetType,
  type IAssetValue,
  IOrderSide,
  ISortDirection,
  type ITransactionFilters,
  type ITransactionsOldQuery,
  ITransactionStatus,
  ITransactionType,
  TransactionFilterInputDocument,
  TransactionsOldDocument,
  useDeleteTransactionOldMutation,
  useDownloadTransactionsOldMutation,
  useTransactionsOldQuery,
} from '../../../generated/graphql';
import { isValidDayjsDateRange } from '../../date.utils';
import { DateTimeFormat, formatDate, formatNumber } from '../../formatter.utils';
import type { IAssetValueToAssetValue } from '../../market/asset/Asset.types';
import AssetLabel from '../../market/asset/AssetLabel';
import { createAssetAutocompleteProps, useAssetPaginatedOptions } from '../../market/asset/AssetService';
import AssetValue from '../../market/asset/AssetValueView';
import { SubAccountLabel } from '../../portfolio/account/SubAccountLabel.tsx';
import { convertDateRangeToSinceToDateTime } from 'components/technical/inputs/date/dateRange.utils.ts';
import { AccountLabel } from '../../portfolio/account/AccountLabel.tsx';
import type { StaticMultiAutocompleteProps } from '../../technical/inputs/Autocomplete/StaticMultiAutocomplete.props.ts';
import type { GInputProps } from '../../technical/inputs/GInput/GInput.props.ts';
import { DialogMenuItem } from '../../technical/DialogDropdown/DialogMenuItem.tsx';
import GMultiAutocomplete from 'components/technical/inputs/Autocomplete/GMultiAutocomplete.tsx';
import type { GMultiAutocompleteProps } from 'components/technical/inputs/Autocomplete/GMultiAutocomplete.props.ts';
import type { NotVerifiedAsset } from '../../market/asset/AssetLabelService.ts';

type TransactionRawRow = ITransactionsOldQuery['bookkeeping']['transactions']['data'][number];

type Transaction = {
  [key in keyof TransactionRawRow]: NonNullable<TransactionRawRow[key]> extends IAssetValue
    ? IAssetValueToAssetValue<TransactionRawRow[key]>
    : TransactionRawRow[key];
};

const formatTransactionType = (value: string): string => capitalize(value.split('_').join(' '));

const transactionTypes: StaticAutocompleteOption<string>[] = [
  ...Object.entries(ITransactionType).map(([name, value]) => ({
    label: formatTransactionType(value),
    value: value,
    key: name,
    searchText: formatTransactionType(value),
  })),
];

const orderSides: StaticAutocompleteOption<string | null>[] = [
  {
    label: 'Not applicable',
    value: null,
    key: 'na',
    searchText: 'Not applicable',
  },
  ...Object.entries(IOrderSide).map(([name, value]) => ({
    label: name,
    value: value,
    key: name,
    searchText: name,
  })),
];

const DEBOUNCE_DELAY_MS = 800;

function notEmpty<TValue>(value: TValue | null | undefined): value is TValue {
  return value !== null && value !== undefined;
}

const executedAtColumnId = 'executedAt';
const createBaseColumns = (navigableAssetIds: Set<string>, timezone: string) => [
  {
    id: executedAtColumnId,
    accessorKey: executedAtColumnId,
    cell: (props: CellContext<Transaction, unknown>): ReactNode =>
      formatDate(props.row.original.executedAt, DateTimeFormat.DateTime, timezone),
    header: 'Executed at (UTC)',
    enableSorting: true,
  },
  {
    header: 'Type',
    cell: (props: CellContext<Transaction, unknown>): ReactNode => {
      const tran = props.row.original;
      const modified = !!tran.originalTransactionId;
      const chip = modified ? (
        <span title="Modified">
          <Avatar
            sx={(theme) => ({
              background: theme.palette.primary['400'],
              color: 'white',
              width: '1.5rem',
              height: '1.5rem',
            })}
          >
            M
          </Avatar>
        </span>
      ) : null;
      return (
        <Stack direction="row" spacing={1}>
          {formatTransactionType(tran.type)} {chip}
        </Stack>
      );
    },
    accessorFn: (tran: Transaction): string => {
      return formatTransactionType(tran.type);
    },
  },
  {
    header: 'Subtype',
    cell: (props: CellContext<Transaction, unknown>): ReactNode => {
      const tran = props.row.original;
      return tran.subType ? tran.subType : '-';
    },
    accessorFn: (tran: Transaction): string => {
      return tran.subType;
    },
  },
  {
    header: 'Order type',
    accessorFn: (trans: Transaction) => capitalize(trans.trade?.order?.type ?? '-'),
  },
  {
    header: 'Side',
    accessorFn: (trans: Transaction) => capitalize(trans.trade?.order?.side ?? '-'),
  },
  {
    header: 'Role',
    accessorFn: (trans: Transaction) => capitalize(trans.trade?.type ?? '-'),
  },
  {
    header: 'Buy',
    accessorFn: (tran: Transaction): string => {
      return tran.credit ? formatNumber(tran.credit.amount) : '-';
    },
  },
  {
    id: 'buyAsset',
    cell: (props: CellContext<Transaction, unknown>): ReactNode => {
      const tran = props.row.original;
      return tran.credit?.asset ? (
        <AssetLabel asset={tran.credit?.asset} link={navigableAssetIds.has(tran.credit.asset.id)} />
      ) : (
        '-'
      );
    },
  },
  {
    id: 'buyMarketValue',
    cell: (props: CellContext<Transaction, unknown>): ReactNode => {
      const tran = props.row.original;
      return tran.credit?.unitValue && tran.credit.amount ? (
        <AssetValue value={bignumber(tran.credit?.unitValue).mul(tran.credit.amount)} asset={dollar} link={false} />
      ) : (
        '-'
      );
    },
  },
  {
    header: 'Sell',
    accessorFn: (tran: Transaction): string => {
      return tran.debit ? formatNumber(tran.debit.amount) : '-';
    },
  },
  {
    id: 'sellAmount',
    cell: (props: CellContext<Transaction, unknown>): ReactNode => {
      const tran = props.row.original;
      return tran.debit?.asset ? (
        <AssetLabel asset={tran.debit?.asset} link={navigableAssetIds.has(tran.debit.asset.id)} />
      ) : (
        '-'
      );
    },
  },
  {
    id: 'sellMarketValue',
    cell: (props: CellContext<Transaction, unknown>): ReactNode => {
      const tran = props.row.original;
      return tran.debit?.unitValue && tran.debit.amount ? (
        <AssetValue value={bignumber(tran.debit?.unitValue).mul(tran.debit.amount)} asset={dollar} link={false} />
      ) : (
        '-'
      );
    },
  },
  {
    header: 'P&L',
    cell: (props: CellContext<Transaction, unknown>): ReactNode => {
      const { journalEntry, debit } = props.row.original;
      if (!journalEntry) {
        return '-';
      }

      if (
        bignumber(journalEntry.debitAmount).isZero() ||
        bignumber(journalEntry.debitRemainingAmount).eq(bignumber(journalEntry.debitAmount))
      ) {
        return '-';
      }

      if (isNil(journalEntry.balance)) {
        return '-';
      }

      if (!bignumber(journalEntry.balance).isFinite()) {
        return '-';
      }

      if (bignumber(journalEntry.debitRemainingAmount).isZero()) {
        const change = journalEntry.weightedBalance ? bignumber(journalEntry.weightedBalance).toNumber() : null;
        return (
          <GValueWithChangeCell
            value={
              !isNil(journalEntry.balance) ? (
                <AssetValue value={journalEntry.balance} asset={dollar} link={false} />
              ) : (
                ''
              )
            }
            change={change}
          />
        );
      }

      return (
        <Stack direction="row" spacing={1}>
          <span>-</span>
          <WarningTooltip fontSize="xl">
            Missing purchase history for [{debit?.asset.symbol}]. Review your transaction history to ensure that all the
            transactions were captured correctly.
          </WarningTooltip>
        </Stack>
      );
    },
  },
  {
    header: 'Account',
    cell: (props: CellContext<Transaction, unknown>): ReactElement => {
      const transaction = props.row.original;
      return <AccountLabel account={transaction.subAccount.account} />;
    },
  },
  {
    header: 'Sub-account',
    cell: (props: CellContext<Transaction, unknown>): ReactElement => {
      const transaction = props.row.original;
      return <SubAccountLabel subAccount={transaction.subAccount} />;
    },
  },
  {
    accessorFn: (trade: Transaction): string => {
      const status = trade.status ?? '';
      if (status === ITransactionStatus.Succeeded) {
        return 'SUCCESS';
      }

      return status;
    },
    header: 'Status',
  },
  {
    header: 'Fee',
    cell: (props: CellContext<Transaction, unknown>): ReactNode => {
      const trans = props.row.original;
      if (!trans.fee) {
        return '-';
      }

      return <AssetValue asset={trans.fee.asset} value={trans.fee.amount} link={false} />;
    },
  },
  {
    header: 'Tags',
    cell: (props: CellContext<Transaction, unknown>): ReactElement => {
      const transaction = props.row.original;
      return <TransactionTagsView tags={transaction.tags ?? []} />;
    },
  },
];

const dollar = { symbol: 'USD', type: IAssetType.Public, id: '123' };
export const TransactionList = ({
  accounts,
  existingTags,
}: {
  accounts: CreateSubAccountIdAutocompleteOptionsInputAccount[];
  existingTags: string[];
}): ReactElement => {
  const [transactionType, setTransactionTransactionType] = useState<string[]>([]);
  const [side, setSide] = useState<(string | null)[]>([]);
  const [accountFilter, setAccountFilter] = useState<string[]>([]);
  const [subAccountFilter, setSubAccountFilter] = useState<string[]>([]);
  const [dateRange, setDateRange] = useState<[Dayjs, Dayjs] | null>(null);
  const [assetFilter, setAssetFilter] = useState<(NotVerifiedAsset & { id: string })[]>([]);
  const [filterTags, setFilterTags] = useState<string[]>([]);
  const [commentFilter, setCommentFilter] = useState<string>('');
  const [debouncedCommentFilter, debouncedSetCommentFilter] = useState<string>('');
  const accountOptions = useMemo(() => createAccountIdAutocompleteOptions(accounts), [accounts]);
  const subAccountOptions = useMemo(() => createSubAccountIdAutocompleteOptions(accounts), [accounts]);
  const navigableAssetIds = useSearchableAssetsIds();
  const timezone = useUserTimezone();

  useDebounce(
    () => {
      debouncedSetCommentFilter(commentFilter);
    },
    DEBOUNCE_DELAY_MS,
    [commentFilter]
  );

  const [sorting, setSorting] = useState<SortingState>([
    {
      id: executedAtColumnId,
      desc: true,
    },
  ]);

  const [deleteTransaction] = useDeleteTransactionOldMutation({
    refetchQueries: [TransactionsOldDocument, TransactionFilterInputDocument],
  });

  const isValidDateRange = isValidDayjsDateRange(dateRange);

  const { since: gte, to: lte } = isValidDateRange
    ? convertDateRangeToSinceToDateTime(dateRange)
    : {
        since: null,
        to: null,
      };

  const filters = useMemo(() => {
    return {
      type: emptyToUndefined(transactionType),
      orderSide: emptyToUndefined(side),
      accountId: emptyToUndefined(accountFilter),
      subAccountId: emptyToUndefined(subAccountFilter.filter(notEmpty).map((subAccount) => subAccount)),
      executedAt: { gte, lte },
      assetIds: emptyToUndefined(assetFilter.map((asset) => asset?.id ?? null)),
      tags: emptyToUndefined(filterTags),
      comment: debouncedCommentFilter ? debouncedCommentFilter : undefined,
    } satisfies ITransactionFilters;
  }, [
    gte,
    lte,
    transactionType,
    side,
    subAccountFilter,
    assetFilter,
    filterTags,
    debouncedCommentFilter,
    accountFilter,
  ]);

  const { tablePaginator, page } = useTablePaginator({
    filters,
  });

  const sort = useMemo(
    () => ({
      name: executedAtColumnId,
      direction: sorting.find((col) => col.id === executedAtColumnId)?.desc ? ISortDirection.Desc : ISortDirection.Asc,
    }),
    [sorting]
  );

  const [downloadTransactions] = useDownloadTransactionsOldMutation();

  const { data, loading, error } = useTransactionsOldQuery({
    variables: {
      filters,
      pageLimit: page,
      sort,
    },
    skip: !isValidDateRange,
  });

  const { showGraphqlError, showSuccessMessage } = useFeedback();
  const registerActions = useRegisterActions();
  useEffect(() => {
    return registerActions(
      <AsyncActionButton
        {...defaultHeaderActionProps}
        onClick={async (): Promise<void> => {
          try {
            const token = await downloadTransactions({
              variables: {
                filters,
                sort,
              },
            });

            downloadTransactionOrderCsv(token.data!.DownloadTransactions.token);
          } catch (e) {
            console.error('Failed to download csv', e);
            showGraphqlError(e);
          }
        }}
        startDecorator={<TableChartOutlinedIcon />}
      >
        Export CSV
      </AsyncActionButton>
    ).deregister;
  }, [downloadTransactions, registerActions, filters, sort, showGraphqlError]);

  const columnHelper = createColumnHelper<Transaction>();

  const baseColumns = useMemo(() => createBaseColumns(navigableAssetIds, timezone), [navigableAssetIds, timezone]);
  const columns: ColumnDef<Transaction>[] = [
    ...baseColumns,
    columnHelper.display({
      id: 'actions',
      meta: {
        headerStyles: {
          width: '50px',
        },
        align: 'center',
      },
      cell: (props: CellContext<Transaction, unknown>): ReactNode => {
        const existingTransaction = props.row.original;

        const canDelete = existingTransaction.actions.includes(IAction.Delete);
        const canUpdate = existingTransaction.actions.includes(IAction.Update);
        if (!canDelete && !canUpdate) {
          return null;
        }

        return (
          <Stack justifyContent="center" spacing={1.5}>
            <SeeMoreDropdown>
              {canUpdate && (
                <DialogMenuItem
                  renderDialog={({ onClose }): ReactElement => (
                    <UpdateTransactionDialogContainer
                      onClose={onClose}
                      onAdded={onClose}
                      accounts={accounts}
                      existingTransaction={existingTransaction}
                      existingTags={existingTags}
                    />
                  )}
                >
                  Edit
                </DialogMenuItem>
              )}
              {canDelete && (
                <DialogMenuItem
                  renderDialog={({ onClose }): ReactElement => (
                    <ConfirmationDialog
                      onClose={onClose}
                      onApprove={async () => {
                        try {
                          await deleteTransaction({
                            variables: {
                              input: props.row.original.id,
                            },
                          });
                          showSuccessMessage('Transaction successfully deleted');
                        } catch (e) {
                          showGraphqlError(e);
                        }
                        onClose();
                      }}
                    >
                      Are you sure you want to remove transaction?
                    </ConfirmationDialog>
                  )}
                >
                  Remove
                </DialogMenuItem>
              )}
            </SeeMoreDropdown>
          </Stack>
        );
      },
    }),
  ];

  const trans = data?.bookkeeping.transactions;

  const width = 'normal' as const;
  const { getOptions } = useAssetPaginatedOptions();
  return (
    <GTable
      filters={[
        {
          component: DateRangeInput,
          value: dateRange,
          width: width,
          error: isValidDateRange ? undefined : 'Invalid date',
          onChange: setDateRange,
          label: 'Date',
          defaultValue: null,
          defaultFilter: true,
        } satisfies Filter<DateRangeInputProps>,
        {
          component: StaticMultiAutocomplete,
          options: transactionTypes,
          value: transactionType,
          width,
          limitTags: 1,
          onChange: setTransactionTransactionType,
          label: 'Transaction type',
          defaultValue: [],
          defaultFilter: true,
        } satisfies Filter<StaticMultiAutocompleteProps<string>>,
        {
          component: GMultiAutocomplete,
          getOptions,
          ...createAssetAutocompleteProps(),
          value: assetFilter,
          width: width,
          onChange: setAssetFilter,
          label: 'Asset',
          defaultValue: [],
          defaultFilter: true,
        } satisfies Filter<GMultiAutocompleteProps<NotVerifiedAsset & { id: string }>>,
        {
          component: StaticMultiAutocomplete,
          value: accountFilter,
          width,
          ...accountOptions,
          onChange: setAccountFilter,
          label: 'Account',
          defaultValue: [],
        } satisfies Filter<StaticMultiAutocompleteProps<string>>,
        {
          component: StaticMultiAutocomplete,
          value: subAccountFilter,
          width,
          ...subAccountOptions,
          onChange: setSubAccountFilter,
          label: 'Sub-account',
          defaultValue: [],
        } satisfies Filter<StaticMultiAutocompleteProps<string>>,
        {
          component: StaticMultiAutocomplete,
          options: orderSides,
          value: side,
          width,
          limitTags: 2,
          onChange: setSide,
          label: 'Order side',
          defaultValue: [],
        } satisfies Filter<StaticMultiAutocompleteProps<string | null>>,
        {
          component: StaticMultiAutocomplete,
          limitTags: 2,
          options: existingTags.map((tag) => ({
            label: tag,
            value: tag,
            key: tag,
            searchText: tag,
          })),
          value: filterTags,
          width,
          showChipTooltips: true,
          onChange: setFilterTags,
          label: 'Tags',
          defaultValue: [],
        } satisfies Filter<StaticMultiAutocompleteProps<string>>,
        {
          component: GInput,
          width,
          value: commentFilter,
          endDecorator: commentFilter !== debouncedCommentFilter ? <Loader variant="small" /> : null,
          onChange: (val: string | null): void => setCommentFilter(val ?? ''),
          label: 'Comment',
          defaultValue: '',
        } satisfies Filter<GInputProps>,
      ]}
      columns={columns}
      paginator={tablePaginator}
      totalResults={trans?.pageInfo.totalResults}
      onSortingChange={setSorting}
      sortingState={sorting}
      loading={loading}
      error={error}
      data={trans?.data as Transaction[] | undefined}
    />
  );
};
