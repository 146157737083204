import type { ReactElement } from 'react';

import type { SubAccountAssetFilters } from 'components/technical/SubAccountAssetFilterDrawer/UseSubAccountAssetFilters.tsx';
import {
  accountAggregation,
  assetAggregation,
  assetTypeAggregation,
  getGenieGroupAggregations,
  getUserGroupAggregations,
  longShortAggregation,
  subAccountAggregation,
  underlyingAssetAggregation,
  venueAggregation,
} from './PositionAggregationsService.ts';
import { type ISubAccountSnapshotQuery, useSubAccountSnapshotQuery } from '../../../generated/graphql.tsx';
import { useDefaultErrorHandling } from '../../technical/UseDefaultErrorHandling.tsx';
import { calculateBalance, calculateExposure } from '../account/SubAccountPositionsService.ts';
import PortfolioSunburstWithAggregations from './PortfolioSunburstWithAggregations.tsx';
import { UserSettings } from 'components/management/UserSettings.types.ts';
import { useAggregations } from 'UseUserSettingsAggregations.tsx';
import Loader from 'components/technical/Loader/Loader.tsx';
export type PortfolioSunburstPosition = ISubAccountSnapshotQuery['portfolio']['snapshot'][number]['positions'][number];

const PortfolioSnapshotSunburstContainerContainer = (props: {
  subAccountAssetFilters: SubAccountAssetFilters;
  date: UtcDate;
}): ReactElement => {
  const query = useDefaultErrorHandling(
    useSubAccountSnapshotQuery({
      variables: {
        dates: {
          since: props.date,
          to: props.date,
        },
        subAccountAssetFilters: props.subAccountAssetFilters,
      },
    }),
    {
      loaderFullHeight: true,
    }
  );

  const subAccountSnapshots = query.data?.portfolio.snapshot ?? [];
  const groups = query.data?.assets.assetGroups ?? null;

  const allAvailableAggregations = groups
    ? [
        assetAggregation,
        subAccountAggregation,
        accountAggregation,
        venueAggregation,
        assetTypeAggregation,
        longShortAggregation,
        underlyingAssetAggregation,
        ...getGenieGroupAggregations(groups.genieGroups),
        ...getUserGroupAggregations(groups.userGroups),
      ]
    : [];

  const { aggregations, handleSetAggregationConfig, activeAggregationsOrdered, aggregationsByCategory } =
    useAggregations<PortfolioSunburstPosition>({
      allAvailableAggregations,
      defaultAggregations: [],
      userSettingsKey: UserSettings.PortfolioBalanceExposureSunburst,
    });

  const positions = subAccountSnapshots.find((snap) => snap.date === props.date)?.positions ?? [];

  // Render loading state or fallback if the data is not ready
  if (!groups || !allAvailableAggregations.length) {
    return <Loader />;
  }

  return (
    <PortfolioSunburstWithAggregations<PortfolioSunburstPosition>
      positions={positions}
      aggregations={aggregations}
      loaded={query.loaded}
      Fallback={query.Fallback}
      onSetAggregationConfig={handleSetAggregationConfig}
      calculateBalance={(pos) => calculateBalance(pos)}
      calculateExposure={(pos) => calculateExposure(pos, false)}
      activeAggregationsOrdered={activeAggregationsOrdered}
      aggregationsByCategory={aggregationsByCategory}
    />
  );
};

export default PortfolioSnapshotSunburstContainerContainer;
