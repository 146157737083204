import type { StaticAutocompleteOption } from 'components/technical/inputs/Autocomplete/StaticSingleAutocomplete.props';
import {
  IPortfolioDefinitionType,
  type IPortfolioDefNameQuery,
  PortfolioDefNameDocument,
} from '../../../generated/graphql';
import { HEIGHT_PX, PortfolioDefinitionLabel } from './PortfolioDefinitionLabel.tsx';
import type { ApolloClient } from '@apollo/client';
import isNil from 'lodash/fp/isNil';
import groupBy from 'lodash/fp/groupBy';

export const createParentPortfolioDefinitionAutocompleteOptions = <
  TPortfolioDefinition extends { id: string; name: string },
>(
  definitions: Array<TPortfolioDefinition>
): {
  options: StaticAutocompleteOption<TPortfolioDefinition>[];
  optionHeight: number;
  limitTags: number;
  isValueEqual: (a: TPortfolioDefinition | undefined | null, b: TPortfolioDefinition | undefined | null) => boolean;
} => {
  return {
    options: definitions.map((def) => ({
      label: <PortfolioDefinitionLabel definition={def} plain />,
      value: def,
      searchText: def.name,
      key: def.id,
    })),
    optionHeight: HEIGHT_PX,
    limitTags: 1,
    isValueEqual: (a: TPortfolioDefinition | undefined | null, b: TPortfolioDefinition | undefined | null): boolean => {
      return (!a && !b) || a?.id === b?.id;
    },
  };
};

export interface PortfolioDefinitionValue {
  id: string;
  name: string;
  createdFrom?: { name: string; portfolioDefinition: { name: string; id: string } } | null;
}

const getDisplayName = (groupedValue: string, def: { name: string; createdFrom?: { name: string } | null }): string => {
  if (groupedValue === '') {
    return def.name;
  }
  return def.createdFrom?.name ?? '';
};

export const createGroupBy = <
  TPortfolioDefinition extends {
    id: string;
    name: string;
    createdFrom?: { name: string; portfolioDefinition: { name: string; id: string } } | null;
    type: IPortfolioDefinitionType;
  },
>(
  definitions: TPortfolioDefinition[]
): ((val: PortfolioDefinitionValue) => string) => {
  const parentDefinitionToChildCount = new Map<string, number>();
  for (const def of definitions) {
    const parentDefId = def.createdFrom?.portfolioDefinition.id;
    if (!parentDefId) {
      continue;
    }

    parentDefinitionToChildCount.set(parentDefId, (parentDefinitionToChildCount.get(parentDefId) ?? 0) + 1);
  }

  return (val: PortfolioDefinitionValue): string => {
    const parentDef = val.createdFrom?.portfolioDefinition;
    if (!parentDef) {
      return '';
    }

    const sameParentChildrenCount = parentDefinitionToChildCount.get(parentDef.id) ?? 0;
    if (sameParentChildrenCount > 1) {
      return parentDef.name;
    }

    return '';
  };
};

// portfolio definitions are filtered to only contain actual portfolios with cash weights per day
// grouped by rebalanced definition name only when there is more than one rule
export const createPortfolioDefinitionAutocompleteOptions = <
  TPortfolioDefinition extends {
    id: string;
    name: string;
    createdFrom?: { name: string; portfolioDefinition: { name: string; id: string } } | null;
    type: IPortfolioDefinitionType;
  },
>(
  definitions: Array<TPortfolioDefinition>
): {
  options: StaticAutocompleteOption<PortfolioDefinitionValue>[];
  optionHeight: number;
  limitTags: number;
  isValueEqual: (
    a: PortfolioDefinitionValue | undefined | null,
    b: PortfolioDefinitionValue | undefined | null
  ) => boolean;
  groupBy: (val: StaticAutocompleteOption<PortfolioDefinitionValue>) => string;
} => {
  const groupBy = createGroupBy(definitions);
  return {
    options: definitions
      .filter(
        (port) =>
          port.type === IPortfolioDefinitionType.Real ||
          (port.type === IPortfolioDefinitionType.Synthetic && !isNil(port.createdFrom))
      )
      .map((def) => {
        const value = {
          id: def.id,
          name: def.name,
          createdFrom: def.createdFrom,
        };

        return {
          label: (
            <PortfolioDefinitionLabel
              plain
              definition={{
                id: def.id,
                name: getDisplayName(groupBy(value), def),
              }}
            />
          ),
          value: value,
          searchText: def.name,
          key: def.id,
        };
      })
      .toSorted((a, b): number => groupBy(a.value).localeCompare(groupBy(b.value))),
    optionHeight: HEIGHT_PX,
    limitTags: 1,
    groupBy: (option: StaticAutocompleteOption<PortfolioDefinitionValue>): string => {
      return groupBy(option.value);
    },
    isValueEqual: (
      a: PortfolioDefinitionValue | undefined | null,
      b: PortfolioDefinitionValue | undefined | null
    ): boolean => (!a && !b) || a?.id === b?.id,
  };
};

export const getDefinitionName = async (apolloClient: ApolloClient<unknown>, id: string): Promise<{ name: string }> => {
  const result = await apolloClient.query<IPortfolioDefNameQuery>({
    query: PortfolioDefNameDocument,
    variables: { id },
  });

  if (result.error) {
    console.error('Error loading breadcrumb', result.error);
    throw result.error;
  }

  const definition = result.data.portfolio.definition;

  if (!definition) {
    throw new Error(`Couldn't find portfolio definition: ${id}`);
  }

  return definition;
};

export const calculatePortfolioSelection = (
  definitions: {
    id: string;
    name: string;
    genie: boolean;
    type: IPortfolioDefinitionType;
    createdFrom?: { name: string; portfolioDefinition: { id: string; name: string } } | null;
  }[]
): Map<
  string,
  {
    rebalancingRules: { portfolioId: string; ruleName: string }[];
    basePortfolioId: string | null; // for real portfolios - portfolio, for synth rebalanced portfolio id
    name: string;
  }
> => {
  const portfolioSelection = new Map<
    string,
    {
      rebalancingRules: { portfolioId: string; ruleName: string }[];
      basePortfolioId: string | null;
      name: string;
    }
  >();

  const rebalancedIdToRebalancingRules = groupBy(
    (record) => record.rebalancedId,
    definitions
      .filter((def) => !!def.createdFrom)
      .map((def) => {
        return {
          rebalancedId: def.createdFrom?.portfolioDefinition.id!,
          synthPortfolioId: def.id,
          synthPortfolioName: def.name,
          ruleName: def.createdFrom?.name!,
        };
      })
  );
  for (const port of definitions) {
    const createdFrom = port.createdFrom;
    if (
      port.type === IPortfolioDefinitionType.Real ||
      (port.type === IPortfolioDefinitionType.Synthetic && !createdFrom)
    ) {
      portfolioSelection.set(port.id, {
        basePortfolioId: port.id,
        rebalancingRules: [],
        name: port.name,
      });

      continue;
    }

    if (port.type !== IPortfolioDefinitionType.Rebalanced) {
      continue;
    }

    const rules = rebalancedIdToRebalancingRules[port.id];
    if (isNil(rules) || rules.length === 0) {
      continue;
    }

    if (rules.length === 1) {
      const rule = rules[0];
      portfolioSelection.set(rule.synthPortfolioId, {
        basePortfolioId: rule.rebalancedId,
        rebalancingRules: [],
        name: rule.synthPortfolioName,
      });
    } else {
      portfolioSelection.set(port.id, {
        basePortfolioId: port.id,
        rebalancingRules: rules.map((rule) => ({
          portfolioId: rule.synthPortfolioId,
          ruleName: rule.ruleName,
        })),
        name: port.name,
      });
    }
  }

  return portfolioSelection;
};
