import type React from 'react';
import { useState, useEffect } from 'react';
import { Button, Snackbar } from '@mui/joy';

interface VersionDetailsType {
  currentVersion: string;
  isOutdated: boolean;
  setCurrentVersion: (version: string) => void;
}

const fetchMetaVersionFromIndex = async (): Promise<string | null> => {
  try {
    const response = await fetch(`/index.html?cache_buster=${Date.now()}`);
    if (!response.ok) throw new Error('Failed to fetch index.html');

    const htmlText = await response.text();
    const parser = new DOMParser();
    const doc = parser.parseFromString(htmlText, 'text/html');
    const metaTag = doc.querySelector('meta[name="version"]');
    return metaTag?.getAttribute('content') ?? null;
  } catch (error) {
    console.error('Error fetching or parsing index.html:', error);
    return null;
  }
};

const useVersionDetails = (interval = 10000): VersionDetailsType => {
  const [currentVersion, setCurrentVersion] = useState<string>(
    `${import.meta.env.VITE_APP_VERSION}-${import.meta.env.VITE_GIT_COMMIT_HASH}`
  );
  const [isOutdated, setIsOutdated] = useState<boolean>(false);

  useEffect(() => {
    const checkAndUpdateVersion = async () => {
      const fetchedVersion = await fetchMetaVersionFromIndex();

      if (fetchedVersion && fetchedVersion !== currentVersion) {
        console.log('App version mismatch', fetchedVersion, currentVersion);
        setIsOutdated(true);
      }
    };

    const intervalId = setInterval(checkAndUpdateVersion, interval);

    return () => clearInterval(intervalId);
  }, [currentVersion, interval]);

  return { currentVersion, isOutdated, setCurrentVersion };
};

const VersionChecker: React.FC = () => {
  const { isOutdated } = useVersionDetails();

  // Hard reload the page to update
  const handleUpdate = () => {
    window.location.reload();
  };

  return (
    <>
      <Snackbar
        size="sm"
        open={isOutdated}
        sx={{ display: 'flex', flexDirection: 'row', gap: 2, alignItems: 'center', minWidth: 'fit-content' }}
      >
        <div>New version available</div>
        <Button onClick={handleUpdate} variant="soft" sx={{ alignSelf: 'flex-end', fontWeight: 'lighter' }}>
          Update
        </Button>
      </Snackbar>
    </>
  );
};

export default VersionChecker;
