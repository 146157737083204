import type { FunctionComponent } from 'react';
import {
  assetAggregation,
  getGenieGroupAggregations,
  getUserGroupAggregations,
  type GroupWithAssetId,
  longShortCategory,
} from 'components/portfolio/dashboard/PositionAggregationsService.ts';
import PortfolioSunburstWithAggregations from 'components/portfolio/dashboard/PortfolioSunburstWithAggregations.tsx';
import type { BuilderPortfolioPosition } from './PortfolioBuilder.tsx';
import { bignumber } from 'mathjs';
import { UserSettings } from 'components/management/UserSettings.types.ts';

import { useAggregations } from 'UseUserSettingsAggregations.tsx';
import type { Aggregation, AggregationValue } from 'components/portfolio/dashboard/PositionAggregationsService.ts';

type PositionsSunburstProps = {
  positions: BuilderPortfolioPosition[];
  assetGroups: {
    genieGroups: GroupWithAssetId[];
    userGroups: GroupWithAssetId[];
  };
};

const PositionsSunburst: FunctionComponent<PositionsSunburstProps> = ({ assetGroups, positions }) => {
  const allAvailableAggregations: Aggregation<BuilderPortfolioPosition>[] = [
    assetAggregation,
    {
      label: 'Long/short',
      category: longShortCategory,
      calculateValue: (pos: BuilderPortfolioPosition): AggregationValue => {
        const value = pos.value > 0 ? 'Long' : 'Short';
        return {
          id: value,
          label: value,
        };
      },
    },
    ...getGenieGroupAggregations<BuilderPortfolioPosition>(assetGroups.genieGroups),
    ...getUserGroupAggregations<BuilderPortfolioPosition>(assetGroups.userGroups),
  ];

  const { aggregations, handleSetAggregationConfig, activeAggregationsOrdered, aggregationsByCategory } =
    useAggregations<BuilderPortfolioPosition>({
      allAvailableAggregations,
      defaultAggregations: [assetAggregation],
      userSettingsKey: UserSettings.PortfolioBuilderAggregations,
    });

  return (
    <PortfolioSunburstWithAggregations
      positions={positions}
      aggregations={aggregations}
      onSetAggregationConfig={handleSetAggregationConfig}
      calculateBalance={(pos) => bignumber(pos.value)}
      activeAggregationsOrdered={activeAggregationsOrdered}
      aggregationsByCategory={aggregationsByCategory}
    />
  );
};

export default PositionsSunburst;
