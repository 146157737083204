import { Divider, Grid, Stack } from '@mui/joy';
import dayjs from 'dayjs';
import type { ReactElement, ReactNode } from 'react';
import { useForm } from 'react-hook-form';
import { FormDateTimeInput } from 'components/technical/form/FormDateTimeInput';
import FormFreeSoloMultiAutocomplete from 'components/technical/form/FormFreeSoloMultiAutocomplete.tsx';
import FormInput from 'components/technical/form/FormInput';
import FormSelect from 'components/technical/form/FormSelect';
import FormStaticSingleAutocomplete from 'components/technical/form/FormStaticSingleAutocomplete';
import FormTextArea from 'components/technical/form/FormTextArea.tsx';
import GFormProvider from 'components/technical/form/GFormProvider';
import { GraphQLApiFormErrorMessage } from 'components/technical/form/GraphQLApiErrorMessage';
import gYupResolver from 'components/technical/form/gYupResolver';
import SubmitButton from 'components/technical/form/SubmitButton.tsx';
import { type GraphQlErrorHandler, useGraphQLApiError } from 'components/technical/form/UseGraphQLApiError.tsx';
import { Header4 } from 'components/technical/Header4';
import {
  type FormInputFields,
  type FormOutputFields,
  formSchema,
  orderSide,
  orderTypes,
  statusTypes,
  userTransactionTypes,
} from './TransactionForm.validation';
import { createAssetAutocompleteProps, useAccountAssetPaginatedOptions } from '../../../market/asset/AssetService';
import {
  createSubAccountAutocompleteOptions,
  type CreateSubAccountIdAutocompleteOptionsInputAccount,
} from '../../../portfolio/account/AccountService.tsx';
import FormSingleAutocomplete from '../../../technical/form/FormSingleAutocomplete.tsx';
import { TransactionLegList } from './TransactionLegList.tsx';
import { IUserTransactionType } from '../../../../generated/graphql.tsx';

const FORM_SPACING = 1.5;

const grid4ColProps = {
  xs: 12,
  md: 3,
};

const grid3ColProps = {
  xs: 12,
  md: 4,
};

const userTypesWithOrders = [
  IUserTransactionType.Income,
  IUserTransactionType.Loss,
  IUserTransactionType.Trade,
  IUserTransactionType.TradeDerivative,
];

const TransactionForm = (props: {
  onClose: () => void;
  onSubmit: (data: FormOutputFields, onErrorAndThrow: GraphQlErrorHandler) => void;
  onAdded: () => void;
  accounts: CreateSubAccountIdAutocompleteOptionsInputAccount[];
  disableSubAccount?: boolean;
  defaultValues: FormInputFields;
  submitButtonIcon: ReactElement;
  submitButtonText: ReactNode;
  existingTags: string[];
}): ReactElement => {
  const methods = useForm<FormInputFields>({
    resolver: gYupResolver(formSchema),
    defaultValues: props.defaultValues,
    mode: 'onChange',
  });

  const { handleSubmit } = methods;
  const subAccountOptions = createSubAccountAutocompleteOptions(props.accounts);
  const { onErrorAndThrow } = useGraphQLApiError(methods);
  const subAccount = methods.watch('subAccount', props.defaultValues.subAccount);
  const userType = methods.watch('userType', props.defaultValues.userType);
  const disableOrder = !!userType && !userTypesWithOrders.includes(userType);
  const { getOptions } = useAccountAssetPaginatedOptions({
    account: subAccount?.account,
  });

  const sharedInputProps = {
    width: 'fullWidth',
  } as const;

  return (
    <GFormProvider {...methods}>
      <form onSubmit={handleSubmit((data) => props.onSubmit(data as unknown as FormOutputFields, onErrorAndThrow))}>
        <Stack direction={'column'} spacing={3} minHeight={0}>
          <div>
            <Header4 title="General details" />
            <Stack spacing={3}>
              <Grid container>
                <Grid {...grid4ColProps}>
                  <FormSelect<FormInputFields, 'userType'>
                    options={userTransactionTypes}
                    name="userType"
                    label="Type"
                    required
                    {...sharedInputProps}
                    onChange={(newUserTransactionType: IUserTransactionType | null) => {
                      if (newUserTransactionType === null) {
                        return;
                      }

                      if (userTypesWithOrders.includes(newUserTransactionType)) {
                        return;
                      }
                      methods.resetField('order.side', {
                        defaultValue: null,
                      });
                      methods.resetField('order.type', {
                        defaultValue: null,
                      });
                      methods.resetField('order.externalId', {
                        defaultValue: '',
                      });
                    }}
                  />
                </Grid>
                <Grid {...grid4ColProps}>
                  <FormStaticSingleAutocomplete<FormInputFields>
                    {...subAccountOptions}
                    name="subAccount"
                    label="Sub-account"
                    required
                    disabled={props.disableSubAccount}
                    onChange={(value) => {
                      if (!value) {
                        return;
                      }

                      const assetKeys = methods
                        .getValues('legs')
                        .map((_leg, i): `legs.${number}.asset` => `legs.${i}.asset`);
                      methods.trigger(['attributedToAsset', ...assetKeys]);
                    }}
                    {...sharedInputProps}
                  />
                </Grid>
                <Grid {...grid4ColProps}>
                  <FormSelect<FormInputFields>
                    options={statusTypes}
                    name="status"
                    label="Status"
                    required
                    {...sharedInputProps}
                  />
                </Grid>
                <Grid {...grid4ColProps}>
                  <FormDateTimeInput<FormInputFields>
                    required
                    label="Date and time (UTC)"
                    name="time"
                    maxDate={dayjs.utc()}
                    {...sharedInputProps}
                  />
                </Grid>
              </Grid>
              <Divider orientation="horizontal" />
              <Grid container>
                <Grid xs={12} md={6}>
                  <FormFreeSoloMultiAutocomplete
                    fullHeight
                    options={props.existingTags}
                    name="tags"
                    label="Tags"
                    newEntryLabel="(New tag)"
                    limitTags={10}
                    {...sharedInputProps}
                  />
                </Grid>
                <Grid xs={12} md={6}>
                  <FormTextArea name="comment" label="Add Comment" {...sharedInputProps} minRows={3} />
                </Grid>
              </Grid>
              <Divider orientation="horizontal" />
              <Grid container>
                <Grid {...grid3ColProps}>
                  <FormInput name="externalId" label="External id" {...sharedInputProps} />
                </Grid>
                <Grid {...grid3ColProps}>
                  <FormInput name="externalType" label="External type" {...sharedInputProps} />
                </Grid>
                <Grid {...grid3ColProps}>
                  <FormSingleAutocomplete
                    label="Attributed to asset"
                    name="attributedToAsset"
                    menuWidth="xl2"
                    {...createAssetAutocompleteProps()}
                    getOptions={getOptions}
                    {...sharedInputProps}
                  />
                </Grid>
              </Grid>
              <Divider orientation="horizontal" />
              <Grid container>
                <Grid {...grid3ColProps}>
                  <FormSelect<FormInputFields>
                    options={orderSide}
                    disabled={disableOrder}
                    name="order.side"
                    label="Order side"
                    onChange={() => {
                      methods.trigger('order');
                    }}
                    {...sharedInputProps}
                  />
                </Grid>
                <Grid {...grid3ColProps}>
                  <FormSelect<FormInputFields>
                    options={orderTypes}
                    disabled={disableOrder}
                    name="order.type"
                    label="Order type"
                    onChange={() => {
                      methods.trigger('order');
                    }}
                    {...sharedInputProps}
                  />
                </Grid>
                <Grid {...grid3ColProps}>
                  <FormInput<FormInputFields>
                    disabled={disableOrder}
                    name="order.externalId"
                    label="Order external id"
                    onChange={() => {
                      methods.trigger('order');
                    }}
                    {...sharedInputProps}
                  />
                </Grid>
              </Grid>
            </Stack>
          </div>
          <div>
            <Header4 title="Leg" />
            <TransactionLegList />
          </div>
          <Stack alignItems="center" spacing={FORM_SPACING}>
            <GraphQLApiFormErrorMessage />
            <SubmitButton width="xl2" startDecorator={props.submitButtonIcon}>
              {props.submitButtonText}
            </SubmitButton>
          </Stack>
        </Stack>
      </form>
    </GFormProvider>
  );
};

export default TransactionForm;
