import GButton from 'components/technical/inputs/GButton/GButton';
import { Box, ButtonGroup, DialogTitle, Modal, ModalDialog, Sheet, Stack, useTheme } from '@mui/joy';
import { motion } from 'framer-motion';
import { transitionDurationS } from 'theme/consts';

type ConfirmationCloseDialogProps = {
  setShowCloseConfirmation: (showCloseConfirmation: boolean) => void;
  onClose: () => void;
  cancelButtonText?: string;
  exitButtonText?: string;
  position?: 'center' | 'top';
  title?: string;
};

const CloseConfirmation = ({
  setShowCloseConfirmation,
  onClose,
  cancelButtonText = 'Cancel',
  exitButtonText = 'Yes',
  position = 'center',
  title,
}: ConfirmationCloseDialogProps) => {
  const theme = useTheme();

  const body = (
    <Stack gap={2} direction={position === 'center' ? 'column' : 'row'} alignItems={'center'} justifyContent={'center'}>
      {title && (
        <DialogTitle level="h3" sx={{ p: 0 }}>
          {title}
        </DialogTitle>
      )}
      <ButtonGroup size="md" spacing="1rem" aria-label="spacing button group">
        <GButton
          variant={position === 'center' ? 'solid' : 'outlined'}
          color="neutral"
          onClick={() => setShowCloseConfirmation(false)}
        >
          {cancelButtonText}
        </GButton>
        <GButton variant="solid" color="danger" onClick={onClose}>
          {exitButtonText}
        </GButton>
      </ButtonGroup>
    </Stack>
  );

  if (position === 'top') {
    return (
      <motion.div
        initial={{ opacity: 0, scale: 0.1 }}
        animate={{ opacity: 1, scale: 1 }}
        transition={{ duration: transitionDurationS.Standard }}
        style={{
          backgroundColor: theme.vars.palette.background.level1,
          marginBottom: theme.spacing(2),
          alignItems: 'center',
          justifyContent: 'flex-end',
          display: 'flex',
          // zindex to render on top of x button
          zIndex: 'var(--navbar-z-index)',
          width: '100%',
          borderRadius: theme.vars.radius.md,
        }}
      >
        <Sheet variant="outlined" color="neutral" sx={{ p: 1, border: 'transparent', backgroundColor: 'transparent' }}>
          {body}
        </Sheet>
      </motion.div>
    );
  }

  return (
    <Modal
      open
      slotProps={{
        backdrop: {
          sx: {
            background: 'transparent',
            backdropFilter: 'blur(4px)',
          },
        },
      }}
    >
      <ModalDialog
        sx={{
          background: 'transparent',
          border: 0,
          boxShadow: 'none',
        }}
      >
        <Box mx={'auto'}>{body}</Box>
      </ModalDialog>
    </Modal>
  );
};

export default CloseConfirmation;
