import { useUpdateUserSettingsMutation, useUserSettingsSuspenseQuery } from 'generated/graphql';
import { UserSettingsDocument } from 'generated/graphql';
import { useFeedback } from 'components/technical/Feedback/UseFeedback.tsx';
import type { Aggregation } from 'components/portfolio/dashboard/PositionAggregationsService';
import type { UserSettings } from 'components/management/UserSettings.types';
import { isNil } from 'lodash/fp';

type AggregationsByCategory<T> = Record<string, Aggregation<T>>;

export const useAggregations = <T,>({
  allAvailableAggregations,
  defaultAggregations,
  userSettingsKey,
}: {
  allAvailableAggregations: Aggregation<T>[];
  defaultAggregations: Aggregation<T>[];
  userSettingsKey: UserSettings;
}): {
  aggregations: Aggregation<T>[];
  activeAggregationsOrdered: string[];
  aggregationsByCategory: AggregationsByCategory<T>;
  handleSetAggregationConfig: (config: { category: string; active: boolean }[]) => Promise<void>;
} => {
  const { showGraphqlError } = useFeedback();

  const { data } = useUserSettingsSuspenseQuery({
    variables: {
      field: userSettingsKey,
    },
  });

  const extractAggregationFullData = (aggToExtract: { category: string; active: boolean }):
    | (Aggregation<T> & { active: boolean })
    | null => {
    const foundAgg = allAvailableAggregations.find((agg) => agg.category === aggToExtract.category);
    if (!foundAgg) {
      return null;
    }
    return {
      ...foundAgg,
      active: aggToExtract.active ?? false,
    };
  };

  const savedAggregationsRaw =
    !isNil(data?.management?.userSettings) && data.management.userSettings.length > 0
      ? data.management.userSettings
      : defaultAggregations.map((agg) => ({ active: true, ...agg }));

  const savedAggregations = savedAggregationsRaw
    .map(extractAggregationFullData)
    .filter(
      (agg: (Aggregation<T> & { active: boolean }) | null): agg is Aggregation<T> & { active: boolean } => agg !== null
    );

  const missingAggregations = allAvailableAggregations.filter(
    (agg) =>
      !savedAggregations.some((savedAgg: Aggregation<T> & { active: boolean }) => savedAgg.category === agg.category)
  );

  const allAggregationsToUse = [...savedAggregations, ...missingAggregations.map((agg) => ({ active: false, ...agg }))];

  const aggregationsByCategory = createAggregationsByCategory<T>(allAggregationsToUse);

  const [updateUserSettings] = useUpdateUserSettingsMutation();

  const handleSetAggregationConfig = async (config: { category: string; active: boolean }[]): Promise<void> => {
    try {
      await updateUserSettings({
        variables: {
          settings: {
            [userSettingsKey]: config,
          },
        },
        refetchQueries: [UserSettingsDocument],
      });
    } catch (error) {
      showGraphqlError(error);
    }
  };

  const activeAggregationsOrdered = allAggregationsToUse.filter((agg) => agg.active).map((agg) => agg.category);

  return {
    aggregations: allAggregationsToUse,
    aggregationsByCategory,
    handleSetAggregationConfig,
    activeAggregationsOrdered,
  };
};

const createAggregationsByCategory = <T,>(aggregations: Aggregation<T>[]): AggregationsByCategory<T> =>
  Object.fromEntries(aggregations.map((agg) => [agg.category, agg]));
