import { type ReactElement, useEffect, useState } from 'react';
import type { IPortfolioDefinitionType } from '../../../../generated/graphql.tsx';
import { defaultRowSpacing } from '../../../StackSpacing.ts';
import {
  calculatePortfolioSelection,
  createParentPortfolioDefinitionAutocompleteOptions,
} from '../PortfolioDefinitionService.tsx';
import gYupResolver from '../../../technical/form/gYupResolver.ts';
import { useFieldArray, useForm, useFormState } from 'react-hook-form';
import GFormProvider from '../../../technical/form/GFormProvider.tsx';
import FormStaticSingleAutocomplete from '../../../technical/form/FormStaticSingleAutocomplete.tsx';
import { Stack } from '@mui/joy';
import RemoveButton from '../../../technical/RemoveButton.tsx';
import ErrorMessage from '../../../technical/ErrorMessage.tsx';
import AddButton from '../../../technical/AddButton.tsx';
import { FormDateInput } from '../../../technical/form/FormDateInput.tsx';
import SubmitButton from '../../../technical/form/SubmitButton.tsx';
import FormSelect from '../../../technical/form/FormSelect.tsx';
import WarningMessage from '../../../technical/WarningMessage/WarningMessage.tsx';
import { calculateSchema, type FormInputFields } from './PortfolioBacktestingForm.validation.ts';

const PortfolioBacktestingForm = ({
  definitions,
  onSubmit,
  defaultPortfolioIds,
}: {
  definitions: {
    id: string;
    name: string;
    type: IPortfolioDefinitionType;
    genie: boolean;
    dateRange: { since: UtcDate | null; to: UtcDate | null };
    createdFrom?: {
      name: string;
      portfolioDefinition: {
        id: string;
        name: string;
      };
    } | null;
  }[];
  onSubmit: (data: FormInputFields) => Promise<void>;
  defaultPortfolioIds: string[];
}): ReactElement => {
  const portfolioSelection = calculatePortfolioSelection(definitions);

  const portfolioIdToRange = new Map<
    string,
    {
      since: UtcDate | null;
      to: UtcDate | null;
    }
  >(definitions.map((def) => [def.id, def.dateRange]));

  const portfolioDefOptions = createParentPortfolioDefinitionAutocompleteOptions(
    Array.from(portfolioSelection.entries()).map(([id, val]) => ({
      id: id,
      name: val.name,
      basePortfolioId: val.basePortfolioId,
      extraRebalancingSelection: val.rebalancingRules.length > 0,
    }))
  );

  const defaultPortfolio: FormInputFields['portfolios'] = [];
  const defaultSelectedOptions = [];
  const portWithoutOptions = [];
  for (const id of defaultPortfolioIds) {
    const matchingOption = portfolioDefOptions.options.find((opt) => opt.value.basePortfolioId === id);
    if (matchingOption) {
      defaultSelectedOptions.push(matchingOption);
      continue;
    }

    portWithoutOptions.push(id);
  }

  defaultPortfolio.push(
    ...defaultSelectedOptions.map((opt) => ({
      portfolio: opt.value,
      synthPortfolioId: null,
    }))
  );

  if (defaultPortfolio.length === 0) {
    defaultPortfolio.push({
      portfolio: null,
      synthPortfolioId: null,
    });
  }

  const [someInitialPortfolioHidden, setShowInitialPortfolioHidden] = useState(portWithoutOptions.length > 0);

  const methods = useForm<FormInputFields>({
    resolver: gYupResolver(calculateSchema(portfolioIdToRange)),
    defaultValues: {
      range: {
        since: null,
        to: null,
      },
      portfolios: defaultPortfolio,
    },
  });

  const { isSubmitting, isDirty } = methods.formState;
  useEffect(() => {
    if (!isDirty) {
      return;
    }

    setShowInitialPortfolioHidden(false);
  }, [isDirty]);

  const {
    fields: portfolios,
    append,
    remove,
  } = useFieldArray<FormInputFields>({
    control: methods.control,
    name: 'portfolios',
  });

  // field array only rerenders when the array is mutated (adding/removing items), so we need to extra fetch form items
  const updatedPortfolios = methods.watch('portfolios');
  const portfolioIndexToValue = new Map<number, FormInputFields['portfolios'][number] & { id: string }>(
    updatedPortfolios.map((port, i) => [
      i,
      {
        ...port,
        id: portfolios[i].id,
      },
    ])
  );

  const finalPortfoliosWithId = updatedPortfolios.map((port, i) => ({
    ...port,
    ...portfolioIndexToValue.get(i),
  }));

  const since = methods.watch('range.since');
  // biome-ignore lint/correctness/useExhaustiveDependencies:
  useEffect(() => {
    methods.trigger('range.to');
  }, [since, methods]);

  const { errors } = useFormState<FormInputFields>({
    name: 'portfolioLength',
    control: methods.control,
  });

  const portfolioLengthError = errors.portfolioLength?.message;
  return (
    <GFormProvider {...methods}>
      {someInitialPortfolioHidden && (
        <WarningMessage>
          Some initially selected portfolios are hidden. They are not available for backtesting due to lack of data
        </WarningMessage>
      )}
      <form
        onSubmit={methods.handleSubmit(async (data: FormInputFields): Promise<void> => {
          await onSubmit(data);
        })}
      >
        <Stack direction="column" gap={2} alignItems="flex-start">
          <Stack direction="column" gap={defaultRowSpacing}>
            {finalPortfoliosWithId.map((port, index) => (
              <Stack key={port.id} direction="row" gap={1} alignItems="flex-end">
                <FormStaticSingleAutocomplete<FormInputFields>
                  label="Portfolio"
                  width="normal"
                  name={`portfolios.${index}.portfolio`}
                  {...portfolioDefOptions}
                />
                {port.portfolio?.extraRebalancingSelection && (
                  <FormSelect<FormInputFields>
                    label="Rebalancing rule"
                    width="normal"
                    name={`portfolios.${index}.synthPortfolioId`}
                    options={(portfolioSelection.get(port.portfolio.id)?.rebalancingRules ?? []).map((rule) => ({
                      label: rule.ruleName,
                      value: rule.portfolioId,
                      key: rule.ruleName,
                    }))}
                  />
                )}
                <RemoveButton
                  disabled={isSubmitting}
                  onClick={() => {
                    remove(index);
                    methods.trigger('portfolioLength');
                  }}
                />
              </Stack>
            ))}
          </Stack>
          {portfolioLengthError && <ErrorMessage>{portfolioLengthError}</ErrorMessage>}
          <AddButton
            onClick={() => {
              append({ portfolio: null, synthPortfolioId: '' });
              methods.trigger('portfolioLength');
            }}
            disabled={isSubmitting}
          >
            Compare
          </AddButton>
          <Stack direction="row" gap={1}>
            <FormDateInput width="xl" name="range.since" label="From" />
            <FormDateInput width="xl" name="range.to" label="To" />
          </Stack>
          <SubmitButton>Submit</SubmitButton>
        </Stack>
      </form>
    </GFormProvider>
  );
};

export default PortfolioBacktestingForm;
