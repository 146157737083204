import { Stack } from '@mui/joy';
import type { ReactElement } from 'react';
import { useDefaultErrorHandling } from 'components/technical/UseDefaultErrorHandling';
import { useTransactionFilterInputQuery } from '../../../generated/graphql';
import { TransactionList } from './TransactionList.tsx';
import CreateTransactionDialog from './create/CreateTransactionDialog.tsx';
import DialogButton from '../../technical/inputs/GButton/DialogButton.tsx';
import HeaderBar from 'components/technical/HeaderBar/HeaderBar.tsx';
import { Add } from '@mui/icons-material';
import ActionsContextProvider from '../../technical/actions/ActionsContextProvider.tsx';
import Actions from '../../technical/actions/Actions.tsx';

const TransactionDashboard = (): ReactElement => {
  const { data, Fallback, loaded } = useDefaultErrorHandling(useTransactionFilterInputQuery());

  if (!loaded) {
    return <Fallback />;
  }

  const existingTags = data?.bookkeeping.transactionTags ?? [];
  return (
    <Stack spacing={1.5}>
      <ActionsContextProvider>
        <HeaderBar title="Transactions">
          <DialogButton
            renderDialog={({ onClose }): ReactElement => (
              <CreateTransactionDialog
                onClose={onClose}
                onAdded={onClose}
                accounts={data.portfolio.accounts}
                existingTags={existingTags}
              />
            )}
            startDecorator={<Add />}
          >
            Add transaction
          </DialogButton>
          <Actions />
        </HeaderBar>

        <TransactionList accounts={data.portfolio.accounts} existingTags={existingTags} />
      </ActionsContextProvider>
    </Stack>
  );
};

export default TransactionDashboard;
