// default preset
export const dailyPnlDefaultPresets = [
  {
    name: 'Default',
    isDefault: true,
    settings: {
      filters: {},
      pivotMode: true,
      columns: [
        {
          colId: 'ag-Grid-AutoColumn',
          width: 155,
          hide: false,
          pinned: null,
          sort: null,
          sortIndex: null,
          aggFunc: null,
          rowGroup: false,
          rowGroupIndex: null,
          pivot: false,
          pivotIndex: null,
          flex: null,
        },
        {
          colId: 'date',
          width: 200,
          hide: false,
          pinned: null,
          sort: 'desc' as const,
          sortIndex: null,
          aggFunc: null,
          rowGroup: true,
          rowGroupIndex: 0,
          pivot: false,
          pivotIndex: null,
          flex: null,
        },
        {
          colId: 'subAccount.account.name',
          width: 200,
          hide: false,
          pinned: null,
          sort: null,
          sortIndex: null,
          aggFunc: null,
          rowGroup: false,
          rowGroupIndex: null,
          pivot: false,
          pivotIndex: null,
          flex: null,
        },
        {
          colId: 'subAccount.name',
          width: 200,
          hide: true,
          pinned: null,
          sort: null,
          sortIndex: null,
          aggFunc: null,
          rowGroup: false,
          rowGroupIndex: null,
          pivot: false,
          pivotIndex: null,
          flex: null,
        },
        {
          colId: 'venue',
          width: 200,
          hide: true,
          pinned: null,
          sort: null,
          sortIndex: null,
          aggFunc: null,
          rowGroup: false,
          rowGroupIndex: null,
          pivot: false,
          pivotIndex: null,
          flex: null,
        },
        {
          colId: '1',
          width: 144,
          hide: false,
          pinned: null,
          sort: null,
          sortIndex: null,
          aggFunc: 'sum',
          rowGroup: false,
          rowGroupIndex: null,
          pivot: false,
          pivotIndex: null,
          flex: null,
        },
        {
          colId: '2',
          width: 118,
          hide: false,
          pinned: null,
          sort: null,
          sortIndex: null,
          aggFunc: 'sum',
          rowGroup: false,
          rowGroupIndex: null,
          pivot: false,
          pivotIndex: null,
          flex: null,
        },
        {
          colId: '3',
          width: 200,
          hide: false,
          pinned: null,
          sort: null,
          sortIndex: null,
          rowGroup: false,
          rowGroupIndex: null,
          pivot: false,
          pivotIndex: null,
          flex: null,
        },
        {
          colId: '4',
          width: 157,
          hide: false,
          pinned: null,
          sort: null,
          sortIndex: null,
          aggFunc: 'sum',
          rowGroup: false,
          rowGroupIndex: null,
          pivot: false,
          pivotIndex: null,
          flex: null,
        },
      ],
    },
  },
];
