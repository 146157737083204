import { Card, Grid, Typography } from '@mui/joy';
import { bignumber } from 'mathjs';
import type { ReactElement } from 'react';

import { PortfolioSunburstWithAggregations } from 'components/portfolio/dashboard/PortfolioSunburstWithAggregations';
import {
  type Aggregation,
  type AggregationValue,
  assetAggregation,
  getGenieGroupAggregations,
  getUserGroupAggregations,
  type GroupWithAssetId,
  longShortCategory,
} from 'components/portfolio/dashboard/PositionAggregationsService.ts';
import AssetOptimizerSolutionPositionsGrid from './AssetOptimizerSolutionPositionsGrid.tsx';
import type { AssetOptimization, AssetSolution } from './Results.types.ts';
import Loader from '../../../technical/Loader/Loader.tsx';
import { calculatePosition } from './Position.utils.ts';
import { useAggregations } from 'UseUserSettingsAggregations.tsx';
import { UserSettings } from 'components/management/UserSettings.types.ts';

type AssetMeasure = AssetOptimization['output'][number]['measures'][number];
type AssetMeasureWithSolutionToCompare = {
  asset: Omit<AssetMeasure['item'], 'derivativeDetails'>;
  solutionAssetMeasure?: AssetMeasure;
  solutionToCompareAssetMeasure?: AssetMeasure;
};

const AssetOptimizerSolutionPositions = ({
  solution,
  compareToSolution,
  assetGroups,
}: {
  solution: AssetSolution;
  compareToSolution: AssetSolution | null;
  assetGroups:
    | {
        genieGroups: GroupWithAssetId[];
        userGroups: GroupWithAssetId[];
      }
    | undefined;
}): ReactElement => {
  const allAvailableAggregations: Aggregation<AssetMeasureWithSolutionToCompare>[] = [
    assetAggregation,
    {
      label: 'Long/short',
      category: longShortCategory,
      calculateValue: (pos: AssetMeasureWithSolutionToCompare): AggregationValue => {
        const value = !pos.solutionAssetMeasure ? '' : pos.solutionAssetMeasure.amount > 0 ? 'Long' : 'Short';
        return {
          id: value,
          label: value,
        };
      },
    },
    ...getGenieGroupAggregations<AssetMeasureWithSolutionToCompare>(assetGroups?.genieGroups ?? []),
    ...getUserGroupAggregations<AssetMeasureWithSolutionToCompare>(assetGroups?.userGroups ?? []),
  ];

  const { aggregations, handleSetAggregationConfig, activeAggregationsOrdered, aggregationsByCategory } =
    useAggregations<AssetMeasureWithSolutionToCompare>({
      allAvailableAggregations,
      defaultAggregations: [assetAggregation],
      userSettingsKey: UserSettings.PortfolioOptimizerSolutionPositionsSunburst,
    });

  if (assetGroups === undefined) {
    return <Loader />;
  }

  const positions = calculatePosition({
    solution,
    compareToSolution,
    getPosition: (item) => item,
  });

  return (
    <>
      <Typography level="h4">Positions</Typography>
      <Grid container>
        <Grid md={8} xs={12}>
          <Card sx={{ height: '100%' }}>
            <AssetOptimizerSolutionPositionsGrid
              solutionName={solution.name}
              compareToSolution={compareToSolution}
              positions={positions}
              assetGroups={assetGroups}
            />
          </Card>
        </Grid>
        <Grid md={4} xs={12}>
          <Card sx={{ height: '100%' }}>
            <PortfolioSunburstWithAggregations<AssetMeasureWithSolutionToCompare>
              positions={positions}
              aggregations={aggregations}
              onSetAggregationConfig={handleSetAggregationConfig}
              calculateBalance={(pos) => bignumber(pos.solutionAssetMeasure?.amount ?? 0)}
              activeAggregationsOrdered={activeAggregationsOrdered}
              aggregationsByCategory={aggregationsByCategory}
            />
          </Card>
        </Grid>
      </Grid>
    </>
  );
};

export default AssetOptimizerSolutionPositions;
