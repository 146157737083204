import type { FunctionComponent, ReactElement } from 'react';
import { Controller, useFormContext } from 'react-hook-form';

import FreeSoloAutocomplete, { type FreeSoloAutocompleteProps } from '../inputs/Autocomplete/FreeSoloAutocomplete';

type FormFreeSoloMultiAutocompleteProps = Omit<
  Extract<FreeSoloAutocompleteProps, { multiple: true }>,
  'value' | 'onChange' | 'onBlur' | 'multiple'
> & {
  name: string;
};

const FormFreeSoloMultiAutocomplete: FunctionComponent<FormFreeSoloMultiAutocompleteProps> = (props) => {
  const { control } = useFormContext();
  return (
    <Controller
      name={props.name}
      control={control}
      render={({ field: { value, onChange, onBlur }, formState, fieldState }): ReactElement => {
        return (
          <FreeSoloAutocomplete
            multiple={true}
            {...props}
            onBlur={onBlur}
            value={value}
            onChange={onChange}
            disabled={!!props.disabled || formState.isSubmitting || formState.disabled}
            error={props.error ? props.error : fieldState.error?.message}
          />
        );
      }}
    />
  );
};

export default FormFreeSoloMultiAutocomplete;
