export enum UserSettings {
  CounterpartyRiskReport = 'counterpartyRiskReport',
  AssetRiskMetricsReport = 'assetRiskMetricsReport',
  PositionsSummaryPresets = 'positionsSummaryPresets',
  PositionsSpotPresets = 'positionsSpotPresets',
  PositionsDerivativesPresets = 'positionsDerivativesPresets',
  TradingActivityPresets = 'tradingActivityPresets',
  ImportPortfolioPositionsPresets = 'importPortfolioPositionsPresets',
  DailyPnlPresets = 'dailyPnlPresets',
  UnifiedPnlReportPresets = 'unifiedPnlReportPresets',
  AssetContributionPresets = 'assetContributionPresets',
  RealizedPnlPresets = 'realizedPnlPresets',
  PortfolioReportPresets = 'portfolioReportPresets',
  TilesDateRange = 'tilesDateRange',
  StressTestResultsRefValues = 'stressTestResultsRefValues',
  StressTestResultsShocks = 'stressTestResultsShocks',
  AssetScreenerPresets = 'assetScreenerPresets',
  PortfolioBuilderAggregations = 'portfolioBuilderAggregations',
  PortfolioOptimizerSolutionPositionsSunburst = 'portfolioOptimizerSolutionPositionsSunburst',
  PortfolioBalanceExposureSunburst = 'portfolioBalanceExposureSunburst',
}
