import { Add } from '@mui/icons-material';
import { Stack } from '@mui/joy';
import type { ReactElement } from 'react';

import { useNavigate } from 'react-router-dom';
import ActionsContextProvider from 'components/technical/actions/ActionsContextProvider.tsx';
import ActionsHeaderBar from 'components/technical/actions/ActionsHeaderBar';
import GTableSkeleton from 'components/technical/GTable/GTableSkeleton';
import DialogButton from 'components/technical/inputs/GButton/DialogButton';
import GButton from 'components/technical/inputs/GButton/GButton.tsx';
import SectionColumn from 'components/technical/layout/Column/SectionColumn';
import { useDefaultErrorHandling } from 'components/technical/UseDefaultErrorHandling';
import CreateTransactionDialog from './CreateTransactionDialog';
import { TransactionList } from './TransactionList';
import { useTransactionFilterInputOldQuery } from '../../../generated/graphql';

const headers = [
  'Executed at',
  'Sub-account',
  'Trade N',
  'Type',
  'Currency pair',
  'Side',
  'Quantity',
  'Price',
  'Cost',
  'Fee',
  'Order n',
];

const TransactionDashboard = (): ReactElement => {
  const { data, Fallback, loaded } = useDefaultErrorHandling(useTransactionFilterInputOldQuery());
  const navigate = useNavigate();

  const existingTags = data?.bookkeeping.transactionTags ?? [];
  if (!loaded) {
    return <Fallback />;
  }

  if (data.portfolio.accounts.length === 0) {
    return <GTableSkeleton headers={headers} />;
  }

  return (
    <Stack spacing={1.5}>
      <Stack direction="row" justifyContent="flex-end" spacing={1.5}>
        <GButton variant="soft" onClick={(): void => navigate('/app/operations/upload-transactions')}>
          Upload CSV
        </GButton>
        <DialogButton
          renderDialog={({ onClose }): ReactElement => (
            <CreateTransactionDialog
              onClose={onClose}
              onAdded={onClose}
              accounts={data.portfolio.accounts}
              existingTags={existingTags}
            />
          )}
          startDecorator={<Add />}
        >
          Add transaction
        </DialogButton>
      </Stack>
      <SectionColumn>
        <ActionsContextProvider>
          <ActionsHeaderBar title="Transactions" />
          <TransactionList accounts={data.portfolio.accounts} existingTags={existingTags} />
        </ActionsContextProvider>
      </SectionColumn>
    </Stack>
  );
};

export default TransactionDashboard;
