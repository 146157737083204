import { Card, Grid, Typography } from '@mui/joy';
import { bignumber } from 'mathjs';
import type { ReactElement } from 'react';

import PortfolioSunburstWithAggregations from 'components/portfolio/dashboard/PortfolioSunburstWithAggregations';
import type { PortfolioOptimization, PortfolioSolution } from './Results.types.ts';
import PortfolioOptimizerSolutionPositionsGrid from './PortfolioOptimizerSolutionPositionsGrid.tsx';
import { calculatePosition } from './Position.utils.ts';
import { assetAggregation } from '../../../portfolio/dashboard/PositionAggregationsService.ts';
import { UserSettings } from 'components/management/UserSettings.types.ts';
import { useAggregations } from 'UseUserSettingsAggregations.tsx';

type PortfolioMeasure = PortfolioOptimization['output'][number]['measures'][number];
type PortfolioMeasureWithSolutionToCompare = {
  asset: { symbol: string; id: string } & PortfolioMeasure['item'];
  solutionAssetMeasure?: PortfolioMeasure;
  solutionToCompareAssetMeasure?: PortfolioMeasure;
};

const PortfolioOptimizerSolutionPositions = ({
  solution,
  compareToSolution,
}: {
  solution: PortfolioSolution;
  compareToSolution: PortfolioSolution | null;
}): ReactElement => {
  const positions = calculatePosition<
    { symbol: string; id: string } & PortfolioMeasure['item'],
    PortfolioOptimization['output'][number]
  >({
    solution,
    compareToSolution,
    getPosition: (item) => ({
      ...item,
      id: item.id,
      symbol: item.name,
    }),
  });

  const { aggregations, handleSetAggregationConfig, activeAggregationsOrdered, aggregationsByCategory } =
    useAggregations<PortfolioMeasureWithSolutionToCompare>({
      allAvailableAggregations: [assetAggregation],
      defaultAggregations: [assetAggregation],
      userSettingsKey: UserSettings.PortfolioBuilderAggregations,
    });

  return (
    <>
      <Typography level="h4">Positions</Typography>
      <Grid container>
        <Grid md={8} xs={12}>
          <Card sx={{ height: '100%' }}>
            <PortfolioOptimizerSolutionPositionsGrid
              solutionName={solution.name}
              compareToSolution={compareToSolution}
              positions={positions}
            />
          </Card>
        </Grid>
        <Grid md={4} xs={12}>
          <Card sx={{ height: '100%' }}>
            <PortfolioSunburstWithAggregations<PortfolioMeasureWithSolutionToCompare>
              positions={positions}
              aggregations={aggregations}
              onSetAggregationConfig={handleSetAggregationConfig}
              activeAggregationsOrdered={activeAggregationsOrdered}
              aggregationsByCategory={aggregationsByCategory}
              calculateBalance={(pos) => bignumber(pos.solutionAssetMeasure?.amount ?? 0)}
            />
          </Card>
        </Grid>
      </Grid>
    </>
  );
};

export default PortfolioOptimizerSolutionPositions;
