import { Add } from '@mui/icons-material';
import { Stack, Typography as JoyTypography } from '@mui/joy';
import type { ReactElement } from 'react';
import { type DefaultValues, type FieldValues, type Path, useForm } from 'react-hook-form';
import type { Schema } from 'yup';

import ExternalLink from 'components/technical/ExternalLink/ExternalLink';
import GFormProvider from 'components/technical/form/GFormProvider';
import { GraphQLApiFormErrorMessage } from 'components/technical/form/GraphQLApiErrorMessage';
import gYupResolver from 'components/technical/form/gYupResolver';
import SubmitButton from 'components/technical/form/SubmitButton';
import { type GraphQlErrorHandler, useGraphQLApiError } from 'components/technical/form/UseGraphQLApiError.tsx';
import GDialogHeader from 'components/technical/GDialog/GDialogHeader.tsx';
import { FormInput } from 'components/technical/inputs';
import GoBackButton from 'components/technical/inputs/GButton/GoBackButton';
import ResponsiveColumn from 'components/technical/layout/Column/ResponsiveColumn';
import { venues } from '../../../venue/VenueData.tsx';
import Onboarding from './Onboarding.tsx';
import { defaultRowSpacing } from '../../../StackSpacing.ts';

const InputCredentialsStep = <TFormValues extends FieldValues>({
  onGoBack,
  handleSubmit,
  defaultValues,
  formSchema,
  venue,
  fields,
  title,
}: {
  onGoBack?: () => void;
  handleSubmit: (
    data: TFormValues,
    onError: GraphQlErrorHandler,
    setError: (field: Path<TFormValues>, error: { type: string; message: string }) => void
  ) => Promise<void>;
  defaultValues: DefaultValues<TFormValues>;
  formSchema: Schema;
  venue: string;
  fields: { label: string; name: string; type?: string | undefined }[];
  title: string;
}): ReactElement => {
  const Disclaimer = venues[venue].disclaimer;
  const methods = useForm<TFormValues>({
    resolver: gYupResolver(formSchema),
    defaultValues,
  });

  // jscpd:ignore-start
  const { onError } = useGraphQLApiError(methods);

  const handleFormSubmit = async (data: TFormValues): Promise<void> => {
    await handleSubmit(data, onError, methods.setError);
  };
  // jscpd:ignore-end

  const ExtraFields = venues[venue].extraFields;
  return (
    <div>
      <GDialogHeader>{title}</GDialogHeader>
      <GFormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(handleFormSubmit)}>
          <Stack spacing={3}>
            <Stack spacing={2}>
              <Stack spacing={defaultRowSpacing}>
                {fields.map((field) => {
                  return (
                    <FormInput
                      key={`input-${field.name}`}
                      type={field.type}
                      label={field.label}
                      name={field.name}
                      autoComplete="off"
                      width="fullWidth"
                      showLabelAboveInput
                    />
                  );
                })}
                {ExtraFields && <ExtraFields />}
              </Stack>
              <Disclaimer />
              <Onboarding venue={venue} />
            </Stack>
            <Stack alignItems="center" spacing={1.5}>
              <GraphQLApiFormErrorMessage />
              <ResponsiveColumn xs={6} spacing={1.5}>
                <SubmitButton width="fullWidth" startDecorator={<Add />}>
                  Connect
                </SubmitButton>
                {onGoBack && <GoBackButton onClick={onGoBack} disabled={methods.formState.isSubmitting} />}
              </ResponsiveColumn>
              <JoyTypography color="neutral" level="body-sm">
                Can&apos;t find the keys?{' '}
                <ExternalLink to="mailto:federico@genieai.tech">Contact our team</ExternalLink> to get help
              </JoyTypography>
            </Stack>
          </Stack>
        </form>
      </GFormProvider>
    </div>
  );
};

export default InputCredentialsStep;
