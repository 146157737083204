import dropRight from 'lodash/fp/dropRight';
import { type FunctionComponent, type ReactElement, useState } from 'react';
import { UserSettings } from 'components/management/UserSettings.types.ts';
import GAgGridPresets from 'components/technical/grids/GAgGridPresets.tsx';
import { useDefaultErrorHandling } from 'components/technical/UseDefaultErrorHandling';
import type { TupleKeyMap } from 'components/TupleKeyMap.ts';
import { useReportAssetGroup } from 'components/UseReportAssetGroups.tsx';

import { getDefaultPresets } from './defaultPresets.ts';
import type { RowData } from './Report.types';
import { mapSubAccountAndDimensionToSubFund } from './Report.utils.tsx';
import { type IPortfolioReportQuery, usePortfolioReportQuery } from '../../../generated/graphql';
import { dateReadableValueGetter } from 'components/technical/grids/agGrid.utils.tsx';
import type { ValueGetterParams } from 'ag-grid-community';
import {
  accountColumn,
  amountColumn,
  balanceColumn,
  clusterColumn,
  exposureColumn,
  nameColumn,
  sideColumn,
  slotColumn,
  subAccountColumn,
  subFundGroupColumn,
  symbolColumn,
  typeColumn,
  underlyingAssetColumn,
  venueColumn,
} from 'components/technical/grids/SharedReportColumns';
import type { Dayjs } from 'dayjs';
import { getDefaultRange } from '../../predefinedDateRanges.ts';
import PredefinedDateRangeButtonsWithCalendar from '../../technical/inputs/date/PredefinedDateRangeButtonsWithCalendar.tsx';
import { useSubAccountAssetFilters } from '../../technical/SubAccountAssetFilterDrawer/UseSubAccountAssetFilters.tsx';
import { convertDateRangeToSinceToDate } from '../../technical/inputs/date/dateRange.utils.ts';
import type { AssetLabelInput } from '../../market/asset/AssetLabelService.ts';
import { timeAvgOfSumsFactory } from './agg.utils.ts';

type ReportProps = {
  data: IPortfolioReportQuery;
  idToSubAccount: Record<
    string,
    {
      id: string;
      name: string;
      account: {
        id: string;
        name: string;
        venue: {
          label: string;
        };
      };
    }
  >;
  assetClusters: string[];
  assetAndGroupClusterMapToGroup: TupleKeyMap<[string, string], string>;
};

const GROUP_COLUMN_MIN_WIDTH = 220;
const dateColId = 'date';
const customAgg = {
  timeAvgOfSums: timeAvgOfSumsFactory(dateColId),
};

const Report: FunctionComponent<ReportProps> = ({
  data,
  assetClusters,
  assetAndGroupClusterMapToGroup,
  idToSubAccount,
}) => {
  const subFunds = data.portfolio.subFunds.list;
  const { subAccountAndDimensionToSubFund, subFundDimensions } = mapSubAccountAndDimensionToSubFund(subFunds);

  // remove the latest snapshot to use only daily snapshots
  const rows: RowData[] = dropRight(1, data.portfolio.snapshot).flatMap((snapshot) =>
    snapshot.positions.map((pos) => ({
      ...pos,
      asset: pos.asset as AssetLabelInput,
      date: snapshot.date,
      subAccount: idToSubAccount[pos.subAccount.id],
    }))
  );

  return (
    <GAgGridPresets<RowData>
      aggFuncs={customAgg}
      presetSettingsKey={UserSettings.PortfolioReportPresets}
      defaultPresets={getDefaultPresets()}
      enableCharts
      enableRangeSelection
      rowData={rows}
      autoGroupColumnDef={{
        minWidth: GROUP_COLUMN_MIN_WIDTH,
      }}
      defaultColDef={{
        resizable: true,
        sortable: true,
        filter: true,
      }}
      sideBar={{
        toolPanels: ['columns', 'filters'],
        defaultToolPanel: 'columns',
      }}
      columnDefs={[
        {
          colId: dateColId,
          headerName: 'Date',
          type: 'dateColumn',
          valueGetter: (params: ValueGetterParams<RowData>): string | undefined =>
            params.data ? dateReadableValueGetter(params.data.date) : undefined,
        },
        {
          headerName: 'Asset Details',
          colId: 'asset-details',
          marryChildren: true,
          children: [
            nameColumn(),
            symbolColumn(),
            underlyingAssetColumn(),
            ...assetClusters.map((cluster) => clusterColumn<RowData>(cluster, assetAndGroupClusterMapToGroup)),
          ],
        },
        sideColumn(),
        typeColumn(),
        slotColumn(),
        {
          headerName: 'Account Details',
          colId: 'account-details',
          marryChildren: true,
          children: [accountColumn(), subAccountColumn(), venueColumn()],
        },
        {
          headerName: 'Sub-funds',
          colId: 'sub-funds',
          marryChildren: true,
          children: subFundDimensions.map((subFundDimension) =>
            subFundGroupColumn<RowData>(subFundDimension, subAccountAndDimensionToSubFund)
          ),
        },
        {
          headerName: 'Positions',
          colId: 'Positions',
          marryChildren: true,
          children: [
            exposureColumn({ sideAware: true, initialHide: true }),
            exposureColumn({ sideAware: false, initialHide: true }),
            balanceColumn(),

            amountColumn({ sideAware: true, initialHide: true }),
            amountColumn({ sideAware: false, initialHide: true }),
          ],
        },
      ]}
    />
  );
};

const ReportWithDateRange = (): ReactElement => {
  const defaultDateRange = getDefaultRange();
  const [dateRange, setDateRange] = useState<[Dayjs, Dayjs] | null>(defaultDateRange.value);

  return (
    <>
      <PredefinedDateRangeButtonsWithCalendar
        alignRight={false}
        defaultValue={defaultDateRange}
        onChange={setDateRange}
      />
      <ReportContainer dateRange={dateRange} />
    </>
  );
};

type ReportContainerProps = {
  dateRange: [Dayjs, Dayjs] | null;
};

const ReportContainer: FunctionComponent<ReportContainerProps> = ({ dateRange }): ReactElement => {
  const { subAccountAssetFilters } = useSubAccountAssetFilters();
  const portfolioReportQueryResults = useDefaultErrorHandling(
    usePortfolioReportQuery({
      variables: {
        ...convertDateRangeToSinceToDate(dateRange),
        subAccountAssetFilters,
      },
    })
  );

  const reportAssetGroup = useReportAssetGroup();

  if (!reportAssetGroup.loaded) {
    return <reportAssetGroup.Fallback />;
  }

  if (!portfolioReportQueryResults.loaded) {
    return <portfolioReportQueryResults.Fallback />;
  }

  const idToSubAccount = Object.fromEntries(
    portfolioReportQueryResults.data.portfolio.accounts.flatMap((acc) =>
      acc.subAccounts.map((sub) => [
        sub.id,
        {
          ...sub,
          account: acc,
        },
      ])
    )
  );

  return (
    <Report
      data={portfolioReportQueryResults.data}
      idToSubAccount={idToSubAccount}
      assetClusters={reportAssetGroup.clusters}
      assetAndGroupClusterMapToGroup={reportAssetGroup.assetAndGroupClusterMapToGroup}
    />
  );
};

export default ReportWithDateRange;
