import { memo, useState, type ReactElement, useMemo } from 'react';
import {
  usePortfolioDetailsCommentaryQuery,
  usePortfolioDetailsSuspenseQuery,
} from '../../../../../generated/graphql.tsx';
import type { BacktestingConfiguration } from '../BacktestConfiguration.types.ts';
import PortfolioAllocationSection from './PortfolioAllocationSection.tsx';
import { Stack } from '@mui/joy';
import PortfolioReturnsSection from './PortfolioReturnsSection.tsx';
import type { AssetGroups } from '../../../../market/asset/groups/AssetGroups.types.ts';
import { convertDateInUtcToUTCISODate, parseUtcDate } from '../../../../date.utils.ts';
import HeaderBar from '../../../../technical/HeaderBar/HeaderBar.tsx';
import AutoAwesomeOutlinedIcon from '@mui/icons-material/AutoAwesomeOutlined';
import IconButton from '@mui/joy/IconButton';
import { useDefaultErrorHandling } from '../../../../technical/UseDefaultErrorHandling.tsx';

const PortfolioDetails = ({
  backtestingConfig,
  assetGroups,
}: {
  backtestingConfig: BacktestingConfiguration;
  assetGroups: AssetGroups;
}): ReactElement => {
  const [enableAi, setEnableAi] = useState<boolean>(false);
  const query = usePortfolioDetailsSuspenseQuery({
    variables: {
      portfolioIds: backtestingConfig.portfolios.map((p) => p.id) ?? [],
      // we want date to filter returns, whereas backend filters by snapshots, so we need to subtract one day
      since: convertDateInUtcToUTCISODate(parseUtcDate(backtestingConfig.range.since).subtract(1, 'day')),
      to: backtestingConfig.range.to,
    },
  });

  const commentaryQuery = useDefaultErrorHandling(
    usePortfolioDetailsCommentaryQuery({
      variables: {
        input: {
          portfolioDefinitionIds: backtestingConfig.portfolios.map((p) => p.id) ?? [],
          since: backtestingConfig.range.since ?? null,
          to: backtestingConfig.range.to ?? null,
        },
      },
    })
  );

  // we want to remove first day 0 returns and first day cash weight. See: GV-7313
  const filteredAnalysis = useMemo(() => {
    const analysis = query.data.portfolio.analysis;
    return analysis.map((portfolioAnalysis) => {
      const returns = portfolioAnalysis.returns;
      if (returns.length === 0) {
        return portfolioAnalysis;
      }

      const firstDay = portfolioAnalysis.returns[0].date;
      return {
        ...portfolioAnalysis,
        returns: portfolioAnalysis.returns.filter((r) => r.date !== firstDay),
        allocations: portfolioAnalysis.allocations.filter((alloc) => alloc.date !== firstDay),
      };
    });
  }, [query.data.portfolio.analysis]);

  if (!query.data) {
    return <></>;
  }

  return (
    <Stack>
      <HeaderBar
        title={
          <Stack direction={'row'} alignItems={'center'} gap={1}>
            Returns
            <IconButton
              onClick={(): void => setEnableAi((val) => !val)}
              variant={enableAi ? 'solid' : 'plain'}
              loading={commentaryQuery.loading}
            >
              <AutoAwesomeOutlinedIcon />
            </IconButton>
          </Stack>
        }
      />
      <PortfolioReturnsSection analysis={filteredAnalysis} enableAi={enableAi} summaryQuery={commentaryQuery} />
      <HeaderBar title={'Exposure'} />
      <PortfolioAllocationSection analysis={filteredAnalysis} {...assetGroups} />
    </Stack>
  );
};

export default memo(PortfolioDetails);
