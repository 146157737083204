import { type ReactElement, Suspense } from 'react';
import { useCreateTransactionBasedPortfolioDialogInputSuspenseQuery } from '../../../../../generated/graphql.tsx';
import FormFreeSoloSingleAutocomplete from '../../../../technical/form/FormFreeSoloSingleAutocomplete.tsx';
import Loader from '../../../../technical/Loader/Loader.tsx';

const TransactionBasedPortfolioFormFields = (): ReactElement => {
  const { data } = useCreateTransactionBasedPortfolioDialogInputSuspenseQuery();
  return (
    <FormFreeSoloSingleAutocomplete
      name={'tag'}
      options={data.bookkeeping.transactionTags ?? []}
      label={'Transaction tag'}
      width={'xl2'}
      newEntryLabel={'New tag'}
    />
  );
};

const TransactionBasedPortfolioFieldsContainer = (): ReactElement => {
  return (
    <Suspense fallback={<Loader />}>
      <TransactionBasedPortfolioFormFields />
    </Suspense>
  );
};

export default TransactionBasedPortfolioFieldsContainer;
