import type { ReactElement } from 'react';
import { type IPortfolioDefinitionSubType, IUserPortfolioDefinitionType } from '../../../../../generated/graphql.tsx';
import CreatePortfolioDialog from '../create/CreatePortfolioDialog.tsx';
import type { FormOutputFields } from '../create/CreatePortfolio.validation.ts';
import {
  type TransactionBasedFormOutputFields,
  transactionBasedSchema,
} from './transactionBasedPortfolioSchema.validation.ts';
import TransactionBasedPortfolioFormFields from './TransactionBasedPortfolioFormFields.tsx';

const CreateRebalancedPortfolioDialog = (props: {
  onClose: () => void;
  onAdded: (defId: string) => void;
  composition: { id: string; weight: number }[];
  subType: IPortfolioDefinitionSubType;
}): ReactElement => {
  return (
    <CreatePortfolioDialog
      onClose={props.onClose}
      onAdded={props.onAdded}
      extraSchema={props.subType === 'TRANSACTION_BASED' ? transactionBasedSchema : undefined}
      createInput={(data: FormOutputFields & Partial<TransactionBasedFormOutputFields>) => {
        return {
          name: data.name,
          description: data.description,
          type: IUserPortfolioDefinitionType.Rebalanced,
          subType: props.subType,
          composition: props.composition,
          tag: data.tag,
        };
      }}
    >
      {props.subType === 'TRANSACTION_BASED' && <TransactionBasedPortfolioFormFields />}
    </CreatePortfolioDialog>
  );
};

export default CreateRebalancedPortfolioDialog;
