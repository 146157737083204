import { pickBy } from 'lodash/fp';
import isEmpty from 'lodash/fp/isEmpty';
import trim from 'lodash/fp/trim';

export const getInitialValues = (fields: { name: string }[]): Record<string, string> => {
  return Object.fromEntries(fields.map((field) => [field.name, '']));
};

export const removeEmptySecrets = (secrets: Record<string, string>): Record<string, string> => {
  return pickBy((value): value is string => !isEmpty(trim(value ?? '')), secrets);
};

export const API_SECRET_FIELD_NAME = 'secrets';
