import HeaderBar from '../../../../technical/HeaderBar/HeaderBar.tsx';
import { Grid, Menu, MenuItem, Stack } from '@mui/joy';
import type { ReactElement, ReactNode } from 'react';
import { useNavigate } from 'react-router';
import type { NotVerifiedAsset } from '../../../../market/asset/AssetLabelService.ts';
import { type IPortfolioDefinition, IPortfolioDefinitionSubType } from '../../../../../generated/graphql.tsx';
import { GCardMenuButton } from '../../../../technical/GCard/GCardMenuButton.tsx';
import { DialogMenuItem } from '../../../../technical/DialogDropdown/DialogMenuItem.tsx';
import DialogDropdown from '../../../../technical/DialogDropdown/DialogDropdown.tsx';
import CreateRebalancedPortfolioDialog from './CreateRebalancedPortfolioDialog.tsx';
import BackupOutlinedIcon from '@mui/icons-material/BackupOutlined';
import DonutLargeOutlinedIcon from '@mui/icons-material/DonutLargeOutlined';
import RebalancedPortfolioCard, { gridWidth } from './RebalancedPortfolioCard.tsx';
import SwapHorizIcon from '@mui/icons-material/SwapHoriz';

const IconAndText = ({ children, title }: { children: ReactNode; title: string }): ReactElement => {
  return (
    <Stack direction="row" gap={1} alignItems="center">
      {children} {title}
    </Stack>
  );
};

export const RebalancedPortfolioDefList = ({
  defs,
  cardHeight,
  selected,
}: {
  defs: (Pick<
    IPortfolioDefinition,
    'createdAt' | 'description' | 'id' | 'name' | 'type' | 'genie' | 'subType' | 'transactionTag'
  > & {
    assets: NotVerifiedAsset[];
  })[];
  cardHeight: string;
  selected: string[];
}): ReactElement => {
  const navigate = useNavigate();

  return (
    <>
      <HeaderBar
        title="Computed portfolios"
        sx={{
          cursor: 'default',
        }}
      />
      <Grid container>
        <DialogDropdown>
          <GCardMenuButton height={cardHeight} gridWidth={gridWidth} />
          <Menu
            modifiers={[
              {
                name: 'offset',
                options: {
                  offset: [0, -30],
                },
              },
            ]}
          >
            <MenuItem
              onClick={() => {
                navigate('/app/copilot/portfolio-builder');
              }}
            >
              <IconAndText title={'Define portfolio composition'}>
                <DonutLargeOutlinedIcon fontSize="md" />
              </IconAndText>
            </MenuItem>
            <DialogMenuItem
              renderDialog={({ onClose }): ReactElement => (
                <CreateRebalancedPortfolioDialog
                  onClose={onClose}
                  onAdded={(defId) => {
                    navigate(`/app/copilot/lab/portfolio/${defId}/import-positions`);
                    onClose();
                  }}
                  composition={[]}
                  subType={IPortfolioDefinitionSubType.PositionBased}
                />
              )}
            >
              <IconAndText title={'Upload portfolio composition history'}>
                <BackupOutlinedIcon fontSize="md" />
              </IconAndText>
            </DialogMenuItem>
            <DialogMenuItem
              renderDialog={({ onClose }): ReactElement => (
                <CreateRebalancedPortfolioDialog
                  onClose={onClose}
                  onAdded={() => {
                    onClose();
                  }}
                  composition={[]}
                  subType={IPortfolioDefinitionSubType.TransactionBased}
                />
              )}
            >
              <IconAndText title={'Use existing transactions'}>
                <SwapHorizIcon fontSize="md" />
              </IconAndText>
            </DialogMenuItem>
          </Menu>
        </DialogDropdown>
        {defs.map((def) => (
          <RebalancedPortfolioCard key={def.id} height={cardHeight} selected={selected.includes(def.id)} def={def} />
        ))}
      </Grid>
    </>
  );
};
