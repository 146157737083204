import { Box } from '@mui/joy';
import type { ReactElement } from 'react';
import { defaultHeaderActionProps } from 'components/technical/HeaderBar/DefaultHeaderActionProps.ts';
import { Gear } from 'components/technical/icons';
import DialogButton from 'components/technical/inputs/GButton/DialogButton';

import type { Aggregation } from './PositionAggregationsService.ts';
import EditSunburstAggregationsDialog from './EditSunburstAggregationsDialog.tsx';
import PortfolioSnapshotSunburst, { type PortfolioSnapshotSunburstAsset } from './PortfolioSnapshotSunburst.tsx';
import type { TFallback } from '../../technical/UseDefaultErrorHandling.tsx';
import type { BigNumber } from 'mathjs';

type PortfolioSunburstWithAggregationsProps<TPosition extends PortfolioSnapshotSunburstAsset> = {
  aggregations: Aggregation<TPosition>[];
  positions: Array<TPosition>;
  activeAggregationsOrdered: string[];
  aggregationsByCategory: Record<string, Aggregation<TPosition>>;
  onSetAggregationConfig: (config: { category: string; active: boolean }[]) => void;
  calculateBalance: (position: TPosition) => BigNumber | undefined;
  calculateExposure?: (position: TPosition) => BigNumber | undefined;
  loaded?: boolean;
  Fallback?: TFallback;
};

export function PortfolioSunburstWithAggregations<TPosition extends PortfolioSnapshotSunburstAsset>({
  loaded,
  positions,
  aggregations,
  Fallback,
  aggregationsByCategory,
  onSetAggregationConfig,
  calculateBalance,
  calculateExposure,
  activeAggregationsOrdered,
}: PortfolioSunburstWithAggregationsProps<TPosition>): ReactElement {
  const handleEditAggregations = (
    selectedAggregations: { category: string; active: boolean }[],
    onClose?: () => void
  ): void => {
    onSetAggregationConfig(selectedAggregations);
    onClose?.();
  };

  return (
    <>
      {aggregations.length !== 0 && (
        <Box position="relative" textAlign="end" zIndex={1}>
          <DialogButton
            {...defaultHeaderActionProps}
            paddingVariant={'short'}
            sx={{ position: 'absolute', right: 0 }}
            renderDialog={({ onClose }): ReactElement => (
              <EditSunburstAggregationsDialog
                onClose={onClose}
                activeAggregations={activeAggregationsOrdered}
                availableAggregations={aggregations}
                onEdited={(selectedAggregations: { category: string; active: boolean }[]): void => {
                  handleEditAggregations(selectedAggregations, onClose);
                }}
              />
            )}
          >
            <Gear color="inherit" fontSize="xl2" />
          </DialogButton>
        </Box>
      )}
      <PortfolioSnapshotSunburst
        loaded={loaded}
        Fallback={Fallback}
        positions={positions}
        aggregationOrder={activeAggregationsOrdered}
        aggregationsByCategory={aggregationsByCategory}
        calculateBalance={calculateBalance}
        calculateExposure={calculateExposure}
      />
    </>
  );
}

export default PortfolioSunburstWithAggregations;
