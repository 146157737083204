import { IReactionType } from '../../generated/graphql.tsx';
import type { ReactElement } from 'react';
import { Stack } from '@mui/joy';
import {
  ThumbDownAlt as ThumbDownFilled,
  ThumbDownOffAlt as ThumbDownOutlined,
  ThumbUpAlt as ThumbUpFilled,
  ThumbUpOffAlt as ThumbUpOutlined,
} from '@mui/icons-material';
import IconButton from '@mui/joy/IconButton';

const calculateNewReaction = (
  reaction: IReactionType,
  prevValue: IReactionType | null | undefined
): IReactionType | null => {
  return prevValue !== null && prevValue === reaction ? null : reaction;
};

export const Reaction = (props: {
  loading: boolean;
  onUpdated: (react: IReactionType | null) => Promise<void>;
  reaction: IReactionType | undefined | null;
  iconFontSize: 'large' | 'medium' | 'small';
}): ReactElement => {
  return (
    <Stack direction="row" alignItems="center" columnGap={0.5}>
      <IconButton
        size="sm"
        loading={props.loading && props.reaction === IReactionType.Down}
        variant="plain"
        color={'primary'}
        onClick={() => {
          const newReaction = calculateNewReaction(IReactionType.Down, props.reaction);
          props.onUpdated(newReaction);
        }}
      >
        {props.reaction === IReactionType.Down ? (
          <ThumbDownFilled fontSize={props.iconFontSize} />
        ) : (
          <ThumbDownOutlined fontSize={props.iconFontSize} />
        )}
      </IconButton>
      <IconButton
        size="sm"
        loading={props.loading && props.reaction === IReactionType.Up}
        variant="plain"
        color={'primary'}
        onClick={() => {
          const newReaction = calculateNewReaction(IReactionType.Up, props.reaction);
          props.onUpdated(newReaction);
        }}
      >
        {props.reaction === IReactionType.Up ? (
          <ThumbUpFilled fontSize={props.iconFontSize} />
        ) : (
          <ThumbUpOutlined fontSize={props.iconFontSize} />
        )}
      </IconButton>
    </Stack>
  );
};
